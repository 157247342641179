import { Box } from "@mui/material";
import CustomCheckBox from "Components/Common/CustomCheckBox";
import React from "react";

const QuestionItem = ({ question }) => {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#1e1e1e",
          padding: "10px",
          borderRadius: "10px",
          marginBottom: "10px",
        }}
      >
        <Box
          sx={{
            color: "#fff",
            fontSize: "1.2rem",
            marginBottom: "10px",
          }}
        >
          {question.text}
        </Box>
        <Box>
          <Box
            sx={{
              backgroundColor: "#1e1e1e",
              padding: "10px",
              borderRadius: "10px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
          >
            <CustomCheckBox
              name={question._id}
              getOptionsLabel={(id) => question.options.find((option) => option.option === id).option}
              options={question.options.map((option) => option.option)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionItem;
