import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "Images/logo.png";
import CustomText from "Components/Common/CustomText";
import { formatDate } from "helpers/format_date";

const ClientEventCard = ({ event }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        boxShadow: 1,
        borderRadius: 1,
        height: "200px",
        margin: "10px",
        width: "45%",
        backgroundColor: "#1e1e1e",
        color: "#fff",
        borderRadius: 2,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        ":hover": {
          transform: "scale(1.05)",
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.5)",
        },
        cursor: "pointer",
        // padding: "10px",
      }}
      onClick={() => navigate(`/events/${event._id}/`)}
    >
      <img
        src={logo}
        alt={event.title}
        style={{
          width: "100%",
          height: "130px",
          objectFit: "cover",
          boxShadow: 1,
          borderRadius: "14px",
          // borderBottom: "1px solid #f0f0f0",
        }}
      />
      <Box
        sx={{
          marginTop: 1,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "40%",
          }}
        >
          <CustomText
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              WebkitLineClamp: "2",
              color: "#fff",
            }}
            text={event.name}
          />
          <Typography
            sx={{
              fontSize: "14px",
              color: "#aaa",
              margin: "5px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {event.description}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            padding: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              color: "#fff",
            }}
          >
            {event.paying ? `${event.price} DT` : "Gratuit"}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#aaa",
            }}
          >
            {formatDate(event.start_date)} - {formatDate(event.end_date)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientEventCard;
