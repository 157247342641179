import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import CustomField from "Components/Common/CustomField";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import CustomDropDown from "Components/Common/CustomDropDown";
import { toast } from "react-toastify";
import { gamesDataList } from "Pages/data";
import CustomButton from "Components/Common/CustomButton";

const AddEditMooc = () => {
  const { moocId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getMoocData = async () => {
      try {
      } catch (error) {
        toast(`${error}`);
      }
    };
    if (moocId) {
      getMoocData();
    }
  }, []);

  const onSubmit = async (values) => {
    try {
      const data = {
        title: values.title,
        description: values.description,
        game: values.game,
      };
      if (moocId) {
        navigate("/moocs");
      } else {
        navigate("/moocs");
      }
    } catch (error) {
      toast(`${error}`);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={{
        title: "",
        description: "",
        game: gamesDataList[0],
      }}
      // validationSchema={}
      validateOnMount={true}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Box
          // sx={{
          //   "span.error-message": {
          //     m: ".5px -10px",
          //     fontSize: "12px",
          //     color: theme.palette.error.main,
          //     position: "absolute",
          //     whiteSpace: "nowrap",
          //     overflow: "hidden",
          //     textOverflow: "ellipsis",
          //     maxWidth: "100%",
          //   },
          // }}
          sx={{}}
        >
          {isLoading ? (
            <CustomCircularProgress />
          ) : (
            <>
              <Form onSubmit={(e) => handleSubmit(e)}>
                {/* Header */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "20px 10px",
                    backgroundColor: theme.palette.primary.main,
                    position: "sticky",
                    top: "0",
                    zIndex: "1",
                  }}
                >
                  <Typography variant="h3" fontWeight="bold" color="secondary">
                    Nouveau Mooc
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <CustomButton
                      text={"Annuler"}
                      onClick={() => navigate(-1)}
                    />
                    <Button
                      sx={{
                        width: "100px",
                        fontSize: "16px",
                        fontWeight: "400",
                        backgroundColor: theme.palette.alt.main,
                        textTransform: "none",
                        ":hover": {
                          backgroundColor: theme.palette.alt[400],
                        },
                      }}
                      type="submit"
                    >
                      {moocId ? "Modifier" : "Ajouter"}
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderTopLeftRadius: "9px",
                    borderTopRightRadius: "9px",
                    padding: "5px",
                    p: "20px 20px 35px 15px",
                    backgroundColor: theme.palette.grey[100],
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={theme.palette.secondary.main}
                  >
                    Informations Général
                  </Typography>
                  <CustomField
                    value={values.title}
                    isDisabled={false}
                    name="title"
                    type="text"
                    title="Titre du Mooc"
                  />
                  <CustomField
                    value={values.description}
                    isDisabled={false}
                    name="description"
                    type="text"
                    title="Description"
                    multiline={true}
                    rows={4}
                  />
                  <Typography
                    height="15px"
                    variant="h6"
                    color={theme.palette.grey[600]}
                    mb=".6rem"
                    ml="5px"
                  >
                    Jeux
                  </Typography>
                  <CustomDropDown
                    name="game"
                    value={values.game}
                    items={gamesDataList}
                    getItems={(item) => item.name}
                  />
                </Box>
              </Form>
              {/* <PopUp open={addSiteSuccessOpen}>
                <AddSuccessPopUp
                  title={"Ajout de chantier confirmé"}
                  onClick={() => {
                    setAddSuccessOpen(false);
                    navigate("/sites");
                  }}
                />
              </PopUp> */}
            </>
          )}
        </Box>
      )}
    </Formik>
  );
};

export default AddEditMooc;
