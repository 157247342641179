import { ArrowBackOutlined, DeleteOutline } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Builder from "Components/Common/Builder";
import ErrorText from "Components/Common/ErrorText";
import { ordersDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TextItem from "Components/Common/TextItem";
import OrderGamesList from "Components/Admin/Orders/OrderGamesList";
import ClientDetails from "Components/Admin/Orders/ClientDetails";

const OrderDetails = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  useEffect(() => {
    const data = ordersDataList.find((item) => item._id == orderId);
    setOrder(data);
  }, [orderId]);
  return (
    <Builder
      builder={() => {
        if (order) {
          return (
            <Box>
              {/* Header */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    startIcon={
                      <ArrowBackOutlined
                        sx={{
                          color: "grey",
                          height: "15px",
                          width: "18px",
                        }}
                      />
                    }
                    sx={{
                      color: "grey",
                      textTransform: "none",
                      fontSize: "14px",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    Retour
                  </Button>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    color={"secondary.light"}
                  >
                    {order.order_number}
                  </Typography>
                </Box>
                <IconButton
                  sx={{
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: "grey",
                  }}
                  onClick={() => {}}
                >
                  <DeleteOutline sx={{ color: "alt.main" }} />
                </IconButton>
              </Box>
              {/* Body */}
              <Box
                sx={{
                  p: "20px",
                }}
              >
                {/* Details */}
                <Box
                  sx={{
                    p: "10px",
                    border: "1px solid black",
                  }}
                >
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    À propos
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "70px",
                      rowGap: "20px",
                      padding: "10px",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                  >
                    <TextItem
                      title="Numéro de commande"
                      subTitle={order.order_number}
                    />
                    <TextItem
                      title="Nombre de jeux"
                      subTitle={order.games.length}
                    />
                    <TextItem title="Prix total" subTitle={order.total_price} />
                    <TextItem
                      title="Réduction"
                      subTitle={
                        order.total_price_after_reduction ? "Oui" : "Non"
                      }
                    />
                    <TextItem
                      title="Prix aprés reduction"
                      subTitle={
                        order.total_price_after_reduction
                          ? order.total_price_after_reduction
                          : "-"
                      }
                    />
                  </Box>
                </Box>
                {/* client and games */}
                <Box
                  sx={{
                    height: "45vh",
                    display: "flex",
                    mt: "20px",
                    gap: "10px",
                  }}
                >
                  {/* client */}
                  <ClientDetails clientId={order.client._id} />
                  {/* games */}
                  <OrderGamesList games={order.games} />
                </Box>
              </Box>
            </Box>
          );
        } else {
          return <ErrorText text={"Societé n'existe pas"} />;
        }
      }}
    />
  );
};

export default OrderDetails;
