import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Card,
  Typography,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import logo from "Images/logo.png";
import { removeFromCart } from "../../../Redux/Actions/CartActions";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { updateQuantity } from "../../../Redux/Actions/CartActions";
import { ArrowBack, ArrowBackOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Builder from "Components/Common/Builder";
import Each from "Components/Common/Each";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import ErrorText from "Components/Common/ErrorText";

const CartItem = ({ item, onRemove, onUpdateQuantity }) => {
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 2,
        padding: 1.5,
        backgroundColor: "#1e1e1e",
        color: "#fff",
        borderRadius: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <img
          src={item.image || logo}
          alt={item.name}
          style={{
            width: 80,
            height: 80,
            borderRadius: 8,
            objectFit: "cover",
            backgroundColor: "#333",
          }}
        />
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {item.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              marginTop: 1,
            }}
          >
            <IconButton
              onClick={() => onUpdateQuantity(item.id, item.quantity - 1)}
              disabled={item.quantity <= 1}
              sx={{ color: "orange" }}
            >
              <RemoveIcon />
            </IconButton>
            <Typography variant="body1">{item.quantity}</Typography>
            <IconButton
              onClick={() => onUpdateQuantity(item.id, item.quantity + 1)}
              sx={{ color: "orange" }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={{ textAlign: "right" }}>
        <Typography variant="h6" color="orange" fontWeight="bold">
          {item.price} Dt x {item.quantity} = {item.price * item.quantity} Dt
        </Typography>
        <IconButton
          onClick={() => onRemove(item.id)}
          sx={{ color: "red", marginTop: 1 }}
          aria-label={`Remove ${item.name}`}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Card>
  );
};

// const CartItem = ({ item, onRemove }) => {
//   return (
//     <Card
//       sx={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "space-between",
//         marginBottom: 2,
//         padding: 1.5,
//         backgroundColor: "#1e1e1e",
//         color: "#fff",
//         borderRadius: 2,
//       }}
//     >
//       <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
//         <img
//           src={item.image || logo}
//           alt={item.name}
//           style={{
//             width: 80,
//             height: 80,
//             borderRadius: 8,
//             objectFit: "cover",
//             backgroundColor: "#333",
//           }}
//         />
//         <Box>
//           <Typography variant="h6" sx={{ fontWeight: "bold" }}>
//             {item.name}
//           </Typography>
//           <Typography variant="body2" color="#aaa">
//             Quantity: {item.quantity}
//           </Typography>
//         </Box>
//       </Box>
//       <Box sx={{ textAlign: "right" }}>
//         <Typography variant="h6" color="orange" fontWeight="bold">
//           ${item.price * item.quantity}
//         </Typography>
//         <IconButton
//           onClick={() => onRemove(item.id)}
//           sx={{ color: "red", marginTop: 1 }}
//           aria-label={`Remove ${item.name}`}
//         >
//           <DeleteIcon />
//         </IconButton>
//       </Box>
//     </Card>
//   );
// };

const ShoppingCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const cart = useSelector((state) => state.cart.items);

  const handleRemove = (id) => {
    dispatch(removeFromCart(id));
  };

  const totalPrice = cart.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const handleUpdateQuantity = (id, quantity) => {
    if (quantity > 0) {
      dispatch(updateQuantity(id, quantity));
    }
  };

  useEffect(() => {
    const getCart = async () => {
      try {
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getCart();
  }, []);
  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)",
        overflow: "auto",
        margin: "auto",
        padding: 3,
        backgroundColor: "#121212",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          marginBottom: 2,
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackOutlined />
        </IconButton>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Panier
        </Typography>
      </Box>
      <Divider sx={{ marginBottom: 2, borderColor: "#333" }} />
      <Box flex={1}>
        <Builder
          builder={() => {
            if (isLoading) {
              return <CustomCircularProgress style={{ color: "#fff" }} />;
            } else if (cart.length > 0) {
              return (
                <>
                  <Each
                    of={cart}
                    render={(item) => (
                      <CartItem
                        item={item}
                        onRemove={handleRemove}
                        onUpdateQuantity={handleUpdateQuantity}
                      />
                    )}
                  />
                  <Divider sx={{ marginY: 2, borderColor: "#333" }} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 2,
                    }}
                  >
                    <Typography variant="h6">Total:</Typography>
                    <Typography variant="h6" color="orange" fontWeight="bold">
                      ${totalPrice.toFixed(2)}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "orange",
                      color: "#fff",
                      fontWeight: "bold",
                      ":hover": {
                        backgroundColor: "#e69500",
                      },
                    }}
                  >
                    Checkout
                  </Button>
                </>
              );
            } else {
              return (
                <ErrorText text={"Panier vide"} style={{ color: "#fff" }} />
              );
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default ShoppingCart;
