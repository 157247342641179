import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { eventsDataList } from "Pages/data";
import ClientEventCard from "Components/Client/Events/ClientEventCard";
import Builder from "Components/Common/Builder";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import Each from "Components/Common/Each";
import ErrorText from "Components/Common/ErrorText";
import { toast } from "react-toastify";

const ClientEventList = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getEvents = async () => {
      try {
        setEvents(eventsDataList);
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
      }
    };
    getEvents();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: 2,
        padding: 3,
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Builder
        builder={() => {
          if (isLoading) {
            return <CustomCircularProgress />;
          } else if (events.length > 0) {
            return (
              <Each
                of={events}
                render={(event) => <ClientEventCard event={event} />}
              />
            );
          } else {
            return <ErrorText text="Aucune jeu trouvé" />;
          }
        }}
      />
    </Box>
  );
};

export default ClientEventList;
