import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  Divider,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { gamesDataList, moocsDataList } from "Pages/data";
import logo from "Images/logo.png";
import {
  Add,
  AddOutlined,
  ArrowBackOutlined,
  BookOutlined,
  RemoveOutlined,
} from "@mui/icons-material";
import Builder from "Components/Common/Builder";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, updateQuantity } from "../../../Redux/Actions/CartActions";
import { toast } from "react-toastify";
import ErrorText from "Components/Common/ErrorText";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";

const ClientGameDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { gameId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [game, setGame] = useState(null);
  const { items } = useSelector((state) => state.cart);

  useEffect(() => {
    const fetchGame = async () => {
      try {
        const data = gamesDataList.find((game) => game._id == gameId);
        setGame(data);
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
      }
    };
    fetchGame();
  }, [gameId]);

  const handleAddToCart = () => {
    dispatch(addToCart({ id: game._id, name: game.name, price: game.price }));
  };

  return (
    <Box
      sx={{
        backgroundColor: "#121212",
        color: "#fff",
        padding: 2,
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          marginBottom: 2,
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackOutlined />
        </IconButton>
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Retour
        </Typography>
      </Box>
      {/* Hero Section */}
      <Builder
        builder={() => {
          if (isLoading) {
            return (
              <CustomCircularProgress
                sx={{
                  height: "70%",
                }}
                style={{ color: "#fff" }}
              />
            );
          } else if (game) {
            return (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 4,
                    alignItems: { xs: "center", md: "flex-start" },
                  }}
                >
                  {/* Game Image */}
                  <Card
                    sx={{
                      maxWidth: "400px",
                      borderRadius: 3,
                      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.5)",
                      overflow: "hidden",
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt={game.name}
                      image={logo}
                      sx={{ objectFit: "cover", height: 400 }}
                    />
                  </Card>

                  {/* Game Details */}
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="h3"
                      sx={{ fontWeight: "bold", marginBottom: 2 }}
                    >
                      {game.name}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="orange"
                      sx={{ marginBottom: 2 }}
                    >
                      ${game.price}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="#aaa"
                      sx={{ marginBottom: 2 }}
                    >
                      {game.description}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="#aaa"
                      sx={{ marginBottom: 1 }}
                    >
                      <strong>Level:</strong> {game.level}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="#aaa"
                      sx={{ marginBottom: 1 }}
                    >
                      <strong>Mooc:</strong> Non
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        marginTop: 3,
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <Builder
                        builder={() => {
                          const item = items.find(
                            (item) => item.id === game._id
                          );
                          if (item) {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                  border: "1px solid orange",
                                  borderRadius: 1,
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    dispatch(
                                      updateQuantity(item.id, item.quantity - 1)
                                    );
                                  }}
                                  disabled={item.quantity <= 1}
                                  sx={{ color: "orange" }}
                                >
                                  <RemoveOutlined />
                                </IconButton>
                                <Typography variant="body1">
                                  {item.quantity}
                                </Typography>
                                <IconButton
                                  onClick={() => {
                                    dispatch(
                                      updateQuantity(item.id, item.quantity + 1)
                                    );
                                  }}
                                  sx={{ color: "orange" }}
                                >
                                  <AddOutlined />
                                </IconButton>
                              </Box>
                            );
                          } else {
                            return (
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "orange",
                                  color: "#fff",
                                  fontWeight: "bold",
                                  ":hover": {
                                    backgroundColor: "#e69500",
                                  },
                                }}
                                onClick={() => {
                                  handleAddToCart();
                                }}
                              >
                                Acheter Maintenant
                              </Button>
                            );
                          }
                        }}
                      />
                      <Builder
                        builder={() => {
                          const mooc = moocsDataList.find(
                            (item) => item.game._id == game._id
                          );
                          if (mooc) {
                            return (
                              <Button
                                variant="outlined"
                                startIcon={<BookOutlined />}
                                sx={{
                                  color: "orange",
                                  borderColor: "orange",
                                  ":hover": {
                                    backgroundColor: "rgba(255, 165, 0, 0.1)",
                                    borderColor: "orange",
                                  },
                                  // height: "35px",
                                  padding: "5px 10px",
                                }}
                                onClick={() => {
                                  navigate(`/moocs/${mooc._id}`);
                                }}
                              >
                                Voir le mooc
                              </Button>
                            );
                          } else {
                            return (
                              <Typography
                                variant="body2"
                                color="#aaa"
                                sx={{
                                  border: "1px solid #aaa",
                                  borderRadius: 1,
                                  padding: "6px 12px",
                                  fontWeight: "bold",
                                  fontSize: "0.9rem",
                                }}
                              >
                                Le mooc n'est pas encore disponible
                              </Typography>
                            );
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ marginY: 4, borderColor: "#333" }} />
                <Box>
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", marginBottom: 3 }}
                  >
                    About the Game
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#aaa"
                    sx={{ marginBottom: 2 }}
                  >
                    {game.rules}
                  </Typography>
                </Box>
              </Box>
            );
          } else {
            return (
              <ErrorText text="Jeu non trouvé" style={{ color: "#fff" }} />
            );
          }
        }}
      />
    </Box>
  );
};

export default ClientGameDetails;
