import { Box, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";

const CustomDatePicker = ({
  name,
  title,
  disabled,
  sx,
  value,
  setFieldValue,
}) => {
  return (
    <Box flex={1}>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: "400",
          color: "grey",
        }}
      >
        {title}
      </Typography>
      <DatePicker
        name={name}
        disabled={disabled}
        sx={{
          width: "100%",
          border: "1px solid",
          borderRaduis: "4px",
          backgroundColor: "primary.main",
          ...sx,
        }}
        value={value}
        onChange={(value) => setFieldValue(name, value.toDate())}
      />
    </Box>
  );
};

export default CustomDatePicker;
