import { Box } from "@mui/material";
import ClientMoocCard from "Components/Client/Moocs/MoocCard";
import Builder from "Components/Common/Builder";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import Each from "Components/Common/Each";
import ErrorText from "Components/Common/ErrorText";
import { moocsDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const ClientMoocsList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [moocs, setMoocs] = useState([]);
  useEffect(() => {
    const getClientMoocs = async () => {
      // get client moocs
      // [GET] /users/me/moocs
      try {
        setMoocs(moocsDataList);
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
      }
    };
    getClientMoocs();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: 2,
        padding: 3,
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Builder
        builder={() => {
          if (isLoading) {
            return <CustomCircularProgress />;
          } else if (moocs.length > 0) {
            return (
              <Each
                of={moocs}
                render={(mooc) => <ClientMoocCard mooc={mooc} />}
              />
            );
          } else {
            return <ErrorText text="Aucun mooc trouvé" />;
          }
        }}
      />
    </Box>
  );
};

export default ClientMoocsList;
