import { Box, IconButton, Typography } from "@mui/material";
import CustomButton from "Components/Common/CustomButton";
import React from "react";
import { useNavigate } from "react-router-dom";
import image from "Images/logo.png";
import { ArrowBackOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { signOut } from "../../../Redux/Actions/AuthentificationActions";

const ClientProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#121212",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          position: "absolute",
          top: "64px",
          left: 0,
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackOutlined />
        </IconButton>
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Retour
        </Typography>
      </Box>
      <Box
        sx={{
          width: "40%",
          height: "100%",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "150px",
            width: "150px",
            borderRadius: "50%",
            border: "1px solid #121212",
            mt: "50px",
          }}
        />
        <Box
          sx={{
            mt: 2,
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#000",
          }}
        >
          Nom Prenom
        </Box>
        <CustomButton
          onClick={() => navigate("/courses")}
          text={"Cours"}
          sx={{
            bgcolor: "#000",
          }}
        />
        <CustomButton
          onClick={() => {
            dispatch(signOut());
          }}
          text={"Déconnexion"}
          sx={{
            bgcolor: "#000",
          }}
        />
      </Box>
    </Box>
  );
};

export default ClientProfile;
