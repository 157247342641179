import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import CustomField from "Components/Common/CustomField";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import CustomDropDown from "Components/Common/CustomDropDown";
import { createNewGame } from "../../../Redux/Actions/GamesActions";
import CustomButton from "Components/Common/CustomButton";

const gameLevels = ["easy", "medium", "hard", "expert"];
const getGameLevelLabel = (level) => {
  const levelLabels = {
    easy: "Facile",
    medium: "Moyen",
    hard: "Difficile",
    expert: "Expert",
  };
  return levelLabels[level] || "Facile";
};

const AddEditGame = () => {
  const dispatch = useDispatch();
  const { gameId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gameId) {
      const fetchGameData = async () => {
        try {
          setIsLoading(true);
          // Fetch and set game data if editing an existing game
        } catch (error) {
          toast.error(`Erreur : ${error.message}`);
        } finally {
          setIsLoading(false);
        }
      };
      fetchGameData();
    }
  }, [gameId]);

  const handleFileChange = useCallback((e, setFieldValue) => {
    const file = e.target.files[0];
    setFieldValue("selectedFile", file);
  }, []);

  const handleDeleteFile = useCallback((setFieldValue) => {
    setFieldValue("selectedFile", null);
  }, []);

  const onSubmit = async (values) => {
    const { name, description, level, rules, price, reduction, selectedFile } =
      values;
    const data = {
      name,
      description,
      level,
      rules,
      price,
      has_reduction: reduction > 0,
      reduction,
      image: selectedFile,
    };

    try {
      if (gameId) {
        navigate("/games");
      } else {
        await dispatch(createNewGame(data));
        toast.success("Jeu ajouté avec succès!");
      }
    } catch (error) {
      toast.error(`Erreur : ${error.message}`);
    }
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={{
        name: "",
        description: "",
        level: gameLevels[0],
        rules: "",
        reduction: 0,
        price: 0,
        selectedFile: null,
      }}
      validateOnMount
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Box>
          {isLoading ? (
            <CustomCircularProgress />
          ) : (
            <Form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
                  backgroundColor: theme.palette.primary.main,
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <Typography variant="h3" fontWeight="bold" color="secondary">
                  {gameId ? "Modifier le jeu" : "Nouveau jeu"}
                </Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate(-1)}
                    type="button"
                  >
                    Annuler
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    {gameId ? "Modifier" : "Ajouter"}
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: theme.palette.grey[100],
                  borderTopLeftRadius: "9px",
                  borderTopRightRadius: "9px",
                }}
              >
                <Typography variant="h5" fontWeight="bold" color="secondary">
                  Informations Générales
                </Typography>
                <CustomField name="name" type="text" title="Nom du jeu" />
                <CustomField
                  name="description"
                  type="text"
                  title="Description"
                  multiline
                  rows={4}
                />
                <CustomField
                  name="rules"
                  type="text"
                  title="Règles"
                  multiline
                  rows={4}
                />
                <CustomField name="price" type="number" title="Prix" />
                <CustomField name="reduction" type="number" title="Réduction" />

                <Typography variant="h6" color={theme.palette.grey[600]} mb={1}>
                  Niveau du jeu
                </Typography>
                <CustomDropDown
                  name="level"
                  value={values.level}
                  items={gameLevels}
                  getItems={getGameLevelLabel}
                />

                <Box mt={3}>
                  <Typography variant="body1" gutterBottom>
                    Ajouter une photo
                  </Typography>
                  <Box display="flex" alignItems="center">
                    {values.selectedFile ? (
                      <Box position="relative">
                        <img
                          src={URL.createObjectURL(values.selectedFile)}
                          alt="Prévisualisation"
                          width={80}
                          height={80}
                          style={{ borderRadius: 8 }}
                        />
                        <Button
                          size="small"
                          color="secondary"
                          onClick={() => handleDeleteFile(setFieldValue)}
                          sx={{
                            position: "absolute",
                            top: -8,
                            right: -8,
                            minWidth: "auto",
                            p: 0.5,
                          }}
                        >
                          X
                        </Button>
                      </Box>
                    ) : (
                      <CustomButton
                        onClick={() => fileInputRef.current.click()}
                        text={"Ajouter"}
                        sx={{
                          bgcolor: "#000",
                        }}
                      />
                    )}
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, setFieldValue)}
                    />
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Box>
      )}
    </Formik>
  );
};

export default AddEditGame;
