import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Typography,
  Box,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "Images/logo.png";
import { ShoppingCart } from "@mui/icons-material";
import { useSelector } from "react-redux";

const NavbarItem = ({ text, to, isSelected }) => {
  return (
    <Typography
      component={Link}
      to={to}
      sx={{
        color: isSelected ? "orange" : "primary.main",
        textDecoration: "none",
        fontWeight: "bold",
        fontSize: "0.9rem",
        "&:hover": {
          color: "orange",
        },
      }}
    >
      {text}
    </Typography>
  );
};

const ClientNavbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.authentification?.user);

  useEffect(() => {
    // get cart items

  }, []);
  return (
    <AppBar
      sx={{
        position: "sticky",
        top: 0,
      }}
      color="inherit"
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minHeight: "60px !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            cursor: "pointer",
            boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.1)",
            padding: "0 7px",
            borderRadius: "5px",
            backgroundColor: "#33312f",
          }}
          onClick={() => navigate("/")}
        >
          <img src={logo} alt="My Store" height="40px" />
          <Typography
            sx={{
              color: "primary.main",
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            Pret à jouer
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <NavbarItem
            text="Accueil"
            to="/"
            isSelected={location.pathname === "/"}
          />
          <NavbarItem
            text="Jeux"
            to="/games"
            isSelected={location.pathname.includes("/games")}
          />
          <NavbarItem
            text="Moocs"
            to="/moocs"
            isSelected={location.pathname.includes("/moocs")}
          />
          <NavbarItem
            text="Evénements"
            to="/events"
            isSelected={location.pathname.includes("/events")}
          />
          <NavbarItem
            text="Profile"
            to="/profile"
            isSelected={location.pathname === "/profile"}
          />
          {/* <Builder
            builder={() => {
              if (user) {
                return (
                  <NavbarItem
                    text="Profile"
                    to="/profile"
                    isSelected={location.pathname === "/profile"}
                  />
                );
              } else {
                return (
                  <NavbarItem
                    text="Login"
                    to="/login"
                    isSelected={location.pathname === "/login"}
                  />
                );
              }
            }}
          /> */}
          {location.pathname !== "/cart" && (
            <div>
              <IconButton
                component={Link}
                to="/cart"
                aria-label="Show cart items"
                color="inherit"
              >
                <Badge color="secondary" style={{ position: "relative" }}>
                  {cart.items.length != 0 && (
                    <span
                      style={{
                        position: "absolute",
                        width: "16px",
                        height: "16px",
                        borderRadius: "50%",
                        backgroundColor: "red",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        right: "-3px",
                        top: "-6px",
                      }}
                    >
                      {cart.items.length}
                    </span>
                  )}
                  <ShoppingCart height="1.2rem" width="1.2rem" />
                </Badge>
              </IconButton>
            </div>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ClientNavbar;
