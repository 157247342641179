import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import MoocsList from "./MoocsList";
import AddEditMooc from "./AddEditMooc";
import MoocDetails from "./MoocDetails";
import Lessons from "../Lessons/Lessons";
import NotFoundError from "Components/Common/NotFoundError";
import FinalTest from "../FinalTest/FinalTest";

const Moocs = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Routes>
        <Route path="/" element={<MoocsList />} />
        <Route path="/add" element={<AddEditMooc />} />
        <Route path="/:moocId/edit" element={<AddEditMooc />} />
        <Route path="/:moocId/lessons/*" element={<Lessons />} />
        <Route path="/:moocId/final-test/*" element={<FinalTest />} />
        <Route path="/:moocId" element={<MoocDetails />} />
        <Route path="*" element={<NotFoundError />} />
      </Routes>
    </Box>
  );
};

export default Moocs;
