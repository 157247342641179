export const finalTestDataList = [
  {
    _id: 1,
    title: "Mastering Poker Final Test",
    description: "Test your knowledge of poker strategies and gameplay.",
    questions: [],
    mooc: 1,
  },
  {
    _id: 2,
    title: "Solitaire Strategies Final Test",
    description: "Evaluate your understanding of Solitaire techniques.",
    questions: [],
    mooc: 2,
  },
  {
    _id: 3,
    title: "Bridge Basics Final Test",
    description:
      "Assess your skills in bidding, strategy, and gameplay in Bridge.",
    questions: [],
    mooc: 3,
  },
  {
    _id: 4,
    title: "Winning at Blackjack Final Test",
    description: "Test your knowledge of Blackjack rules and strategies.",
    questions: [],
    mooc: 4,
  },
  {
    _id: 5,
    title: "Uno for Everyone Final Test",
    description:
      "Check how well you understand the rules and strategies of Uno.",
    questions: [],
    mooc: 5,
  },
  {
    _id: 6,
    title: "Competing in Hearthstone Final Test",
    description: "Evaluate your skills in deck building and advanced tactics.",
    questions: [],
    mooc: 6,
  },
];

export const lessonsDataList = [
  {
    _id: 1,
    title: "Introduction to Poker",
    description:
      "Learn the basics of poker, including hand rankings and game objectives.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 1,
    next_lesson: 2,
    previous_lesson: null,
  },
  {
    _id: 2,
    title: "Betting Strategies",
    description:
      "Explore different betting strategies and when to apply them for success.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 1,
    next_lesson: 3,
    previous_lesson: 1,
  },
  {
    _id: 3,
    title: "Reading Opponents",
    description:
      "Understand how to read your opponents' behavior and betting patterns.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 1,
    next_lesson: null,
    previous_lesson: 2,
  },
  {
    _id: 4,
    title: "Understanding the Game",
    description: "Learn the basic rules and setup for playing Solitaire.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 2,
    next_lesson: 5,
    previous_lesson: null,
  },
  {
    _id: 5,
    title: "Basic Strategies",
    description:
      "Discover strategies to improve your chances of winning at Solitaire.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 2,
    next_lesson: 6,
    previous_lesson: 4,
  },
  {
    _id: 6,
    title: "Advanced Techniques",
    description:
      "Master advanced techniques to tackle complex Solitaire scenarios.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 2,
    next_lesson: null,
    previous_lesson: 5,
  },
  {
    _id: 7,
    title: "Bridge Overview",
    description: "An introduction to the rules and structure of Bridge.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 3,
    next_lesson: 8,
    previous_lesson: null,
  },
  {
    _id: 8,
    title: "Bidding Basics",
    description:
      "Learn the fundamentals of bidding and how it affects gameplay.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 3,
    next_lesson: 9,
    previous_lesson: 7,
  },
  {
    _id: 9,
    title: "Playing the Hand",
    description: "Strategies for playing the hand and winning tricks.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 3,
    next_lesson: null,
    previous_lesson: 8,
  },
  {
    _id: 10,
    title: "Blackjack Fundamentals",
    description:
      "Get familiar with the basic rules and card values in Blackjack.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 4,
    next_lesson: 11,
    previous_lesson: null,
  },
  {
    _id: 11,
    title: "Basic Strategies",
    description: "Learn effective strategies to improve your gameplay.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 4,
    next_lesson: 12,
    previous_lesson: 10,
  },
  {
    _id: 12,
    title: "Advanced Tactics",
    description:
      "Explore advanced tactics for maximizing your chances of winning.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 4,
    next_lesson: null,
    previous_lesson: 11,
  },
  {
    _id: 13,
    title: "Getting Started with Uno",
    description: "Understand the rules and setup for playing Uno.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 5,
    next_lesson: 14,
    previous_lesson: null,
  },
  {
    _id: 14,
    title: "Basic Strategies",
    description: "Learn some strategies to improve your chances of winning.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 5,
    next_lesson: 15,
    previous_lesson: 13,
  },
  {
    _id: 15,
    title: "Game Variations",
    description: "Discover different variations of Uno to keep the game fresh.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 5,
    next_lesson: null,
    previous_lesson: 14,
  },
  {
    _id: 16,
    title: "Introduction to Hearthstone",
    description:
      "Learn the basics of Hearthstone, including card types and game mechanics.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 6,
    next_lesson: 17,
    previous_lesson: null,
  },
  {
    _id: 17,
    title: "Deck Building Basics",
    description:
      "Discover how to build effective decks for different strategies.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 6,
    next_lesson: 18,
    previous_lesson: 16,
  },
  {
    _id: 18,
    title: "Advanced Tactics",
    description: "Master advanced tactics to outplay your opponents.",
    attachments: [],
    questions: [
      {
        _id: 1,
        text: "What is the highest hand in poker?",
        type: "qcm",
        options: [
          {
            option: "Royal Flush",
            is_correct: true,
          },
          {
            option: "Full House",
            is_correct: false,
          },
          {
            option: "Three of a Kind",
            is_correct: false,
          },
          {
            option: "Straight",
            is_correct: false,
          },
        ],
      },
      {
        _id: 2,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 3,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 4,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
      {
        _id: 5,
        text: "What is the primary objective in poker?",
        type: "qcm",
        options: [
          {
            option: "Win as many hands as possible",
            is_correct: false,
          },
          {
            option: "Win the most chips",
            is_correct: false,
          },
          {
            option: "Win the pot",
            is_correct: true,
          },
          {
            option: "Win the game",
            is_correct: false,
          },
        ],
      },
    ],
    mooc: 6,
    next_lesson: null,
    previous_lesson: 17,
  },
];

export const gamesDataList = [
  {
    _id: 1,
    name: "Poker",
    description:
      "A popular gambling game where players bet on the strength of their hands.",
    level: "medium",
    rules:
      "Players receive two cards and use five community cards to create the best hand. Betting rounds occur after each card is revealed.",
    has_reduction: true,
    reduction: 10,
    price: 25,
  },
  {
    _id: 2,
    name: "Solitaire",
    description:
      "A single-player card game that involves sorting cards into specific sequences.",
    level: "easy",
    rules:
      "The goal is to move all cards to four foundation piles in ascending order. Cards can only be moved to the tableau according to specific rules.",
    has_reduction: false,
    reduction: null,
    price: 45,
  },
  {
    _id: 3,
    name: "Bridge",
    description:
      "A trick-taking game using a standard deck of cards, usually played by four players in two partnerships.",
    level: "hard",
    rules:
      "Players bid to determine the trump suit and then try to win as many tricks as possible. Communication between partners is crucial.",
    has_reduction: true,
    reduction: 15,
    price: 10,
  },
  {
    _id: 4,
    name: "Blackjack",
    description:
      "A popular casino game where players try to beat the dealer by getting a hand value as close to 21 as possible without exceeding it.",
    level: "medium",
    rules:
      "Players are dealt two cards and can choose to hit (take another card) or stand (keep current hand). Aces can be worth 1 or 11.",
    has_reduction: true,
    reduction: 5,
    price: 80,
  },
  {
    _id: 5,
    name: "Uno",
    description:
      "A family card game where players aim to be the first to get rid of all their cards.",
    level: "easy",
    rules:
      "Players take turns matching a card from their hand with the current card shown on top of the deck by color or number.",
    has_reduction: false,
    reduction: null,
    price: 69,
  },
  {
    _id: 6,
    name: "Hearthstone",
    description:
      "A digital collectible card game where players use decks to battle against each other.",
    level: "expert",
    rules:
      "Players build decks with cards that represent spells, creatures, and abilities to reduce the opponent's health to zero.",
    has_reduction: true,
    reduction: 20,
    price: 59,
  },
];

export const moocsDataList = [
  {
    _id: 1,
    title: "Mastering Poker",
    description:
      "Dive into the strategic world of Poker, a popular gambling game that challenges players to outwit their opponents through betting and hand strength evaluation. Learn the rules, betting strategies, and tips to improve your game.",
    game: gamesDataList[0],
    lessons: [lessonsDataList[0], lessonsDataList[1], lessonsDataList[2]],
  },
  {
    _id: 2,
    title: "Solitaire Strategies",
    description:
      "Discover the art of Solitaire, a classic single-player card game. This course covers various techniques for sorting cards and achieving the goal of moving all cards to foundation piles in ascending order.",
    game: gamesDataList[1],
    lessons: [lessonsDataList[3], lessonsDataList[4], lessonsDataList[5]],
  },
  {
    _id: 3,
    title: "Bridge Basics and Beyond",
    description:
      "Enhance your skills in Bridge, a complex trick-taking game. This course teaches bidding strategies, partnership communication, and advanced techniques to dominate the game.",
    game: gamesDataList[2],
    lessons: [lessonsDataList[6], lessonsDataList[7], lessonsDataList[8]],
  },
  {
    _id: 4,
    title: "Winning at Blackjack",
    description:
      "Learn the essential strategies of Blackjack, a popular casino game. This course focuses on understanding card values, betting strategies, and decision-making to maximize your chances of beating the dealer.",
    game: gamesDataList[3],
    lessons: [lessonsDataList[9], lessonsDataList[10], lessonsDataList[11]],
  },
  {
    _id: 5,
    title: "Uno for Everyone",
    description:
      "Join the fun with Uno, a family-friendly card game. This course covers the basic rules, strategies, and variations to ensure you’re the first to get rid of your cards.",
    game: gamesDataList[4],
    lessons: [lessonsDataList[12], lessonsDataList[13], lessonsDataList[14]],
  },
  {
    _id: 6,
    title: "Competing in Hearthstone",
    description:
      "Enter the world of Hearthstone, a digital collectible card game that combines strategy and deck building. This course will guide you through deck construction, gameplay tactics, and how to reduce your opponent's health to zero.",
    game: gamesDataList[5],
    lessons: [lessonsDataList[15], lessonsDataList[16], lessonsDataList[17]],
  },
];

export const usersDataList = [
  {
    _id: "u1",
    first_name: "Alice",
    last_name: "Johnson",
    email: "alice.johnson@example.com",
    phone: "123-456-7891",
    address: "456 Manager Rd, Silicon Valley, CA",
    role: "COMPANY",
  },
  {
    _id: "u2",
    first_name: "Bob",
    last_name: "Smith",
    email: "bob.smith@example.com",
    phone: "123-456-7892",
    address: "789 Collaborator St, Silicon Valley, CA",
    role: "COLLABORATOR",
  },
  {
    _id: "u3",
    first_name: "Charlie",
    last_name: "Davis",
    email: "charlie.davis@example.com",
    phone: "123-456-7893",
    address: "321 Collaborator Ave, Silicon Valley, CA",
    role: "COLLABORATOR",
  },
  {
    _id: "u4",
    first_name: "David",
    last_name: "Brown",
    email: "david.brown@example.com",
    phone: "987-654-3211",
    address: "654 Manager Blvd, Eco City, CA",
    role: "COMPANY",
  },
  {
    _id: "u5",
    first_name: "Eve",
    last_name: "White",
    email: "eve.white@example.com",
    phone: "987-654-3212",
    address: "111 Collaborator St, Eco City, CA",
    role: "COLLABORATOR",
  },
  {
    _id: "u6",
    first_name: "Grace",
    last_name: "Lee",
    email: "grace.lee@example.com",
    phone: "555-123-4568",
    address: "789 Manager Way, Wellness City, CA",
    role: "COMPANY",
  },
  {
    _id: "u7",
    first_name: "Henry",
    last_name: "Taylor",
    email: "henry.taylor@example.com",
    phone: "555-123-4569",
    address: "234 Collaborator Blvd, Wellness City, CA",
    role: "COLLABORATOR",
  },
  {
    _id: "u8",
    first_name: "Ivy",
    last_name: "Miller",
    email: "ivy.miller@example.com",
    phone: "555-123-4570",
    address: "345 Collaborator Ave, Wellness City, CA",
    role: "COLLABORATOR",
  },
  {
    _id: "u9",
    first_name: "Jack",
    last_name: "Garcia",
    email: "jack.garcia@example.com",
    phone: "555-987-6543",
    address: "12 Admin St, Admin City, CA",
    role: "COMPANY",
  },
  {
    _id: "u10",
    first_name: "Laura",
    last_name: "Wilson",
    email: "laura.wilson@example.com",
    phone: "555-654-3210",
    address: "34 Client Rd, Client City, CA",
    role: "COLLABORATOR",
  },
  {
    _id: "u11",
    first_name: "Michael",
    last_name: "Smith",
    email: "michael.smith@example.com",
    phone: "555-321-4567",
    address: "98 Admin Ave, Admin City, CA",
    role: "COMPANY",
  },
  {
    _id: "u12",
    first_name: "Nina",
    last_name: "Patel",
    email: "nina.patel@example.com",
    phone: "555-432-1098",
    address: "56 Client Blvd, Client City, CA",
    role: "CLIENT",
  },
  {
    _id: "u13",
    first_name: "Oscar",
    last_name: "Nguyen",
    email: "oscar.nguyen@example.com",
    phone: "555-987-1234",
    address: "77 Collaborator Ln, Tech Town, CA",
    role: "COLLABORATOR",
  },
  {
    _id: "u14",
    first_name: "Paula",
    last_name: "Cruz",
    email: "paula.cruz@example.com",
    phone: "555-654-3211",
    address: "44 Company Way, Business City, CA",
    role: "COMPANY",
  },
  {
    _id: "u15",
    first_name: "Quincy",
    last_name: "Johnson",
    email: "quincy.johnson@example.com",
    phone: "555-321-9876",
    address: "88 Admin Rd, Admin City, CA",
    role: "ADMIN",
  },
  {
    _id: "u16",
    first_name: "Rachel",
    last_name: "Kim",
    email: "rachel.kim@example.com",
    phone: "555-678-1234",
    address: "22 Client St, Client City, CA",
    role: "CLIENT",
  },
  {
    _id: "u17",
    first_name: "Steve",
    last_name: "Roberts",
    email: "steve.roberts@example.com",
    phone: "555-543-2109",
    address: "11 Collaborator Blvd, Tech Town, CA",
    role: "COLLABORATOR",
  },
  {
    _id: "u18",
    first_name: "Tina",
    last_name: "Lee",
    email: "tina.lee@example.com",
    phone: "555-765-4321",
    address: "13 Company St, Business City, CA",
    role: "COMPANY",
  },
  {
    _id: "u19",
    first_name: "Ursula",
    last_name: "Martinez",
    email: "ursula.martinez@example.com",
    phone: "555-234-5678",
    address: "99 Admin Blvd, Admin City, CA",
    role: "COMPANY",
  },
  {
    _id: "u20",
    first_name: "Victor",
    last_name: "Garcia",
    email: "victor.garcia@example.com",
    phone: "555-345-6789",
    address: "77 Client Ave, Client City, CA",
    role: "CLIENT",
  },
];

export const companiesDataList = [
  {
    _id: "1",
    name: "Tech Innovations Inc.",
    description: "Leading provider of cutting-edge technology solutions.",
    email: "info@techinnovations.com",
    phone: "123-456-7890",
    address: "123 Tech Lane, Silicon Valley, CA",
    manager: usersDataList[0],
    collaborators: [usersDataList[2]],
  },
  {
    _id: "2",
    name: "Green Energy Solutions",
    description:
      "Pioneering sustainable energy solutions for a cleaner future.",
    email: "contact@greenenergysol.com",
    phone: "987-654-3210",
    address: "321 Green Way, Eco City, CA",
    manager: usersDataList[3],
    collaborators: [usersDataList[1]],
  },
  {
    _id: "3",
    name: "HealthTech Solutions",
    description: "Innovative solutions for the healthcare industry.",
    email: "info@healthtechsolutions.com",
    phone: "555-123-4567",
    address: "456 Health Blvd, Wellness City, CA",
    manager: usersDataList[5],
    collaborators: [usersDataList[4]],
  },
  {
    _id: "1",
    name: "Tech Innovations Inc.",
    description: "Leading provider of cutting-edge technology solutions.",
    email: "info@techinnovations.com",
    phone: "123-456-7890",
    address: "123 Tech Lane, Silicon Valley, CA",
    manager: usersDataList[13],
    collaborators: [usersDataList[7]],
  },
  {
    _id: "2",
    name: "Green Energy Solutions",
    description:
      "Pioneering sustainable energy solutions for a cleaner future.",
    email: "contact@greenenergysol.com",
    phone: "987-654-3210",
    address: "321 Green Way, Eco City, CA",
    manager: usersDataList[17],
    collaborators: [usersDataList[12]],
  },
  {
    _id: "3",
    name: "HealthTech Solutions",
    description: "Innovative solutions for the healthcare industry.",
    email: "info@healthtechsolutions.com",
    phone: "555-123-4567",
    address: "456 Health Blvd, Wellness City, CA",
    manager: usersDataList[8],
    collaborators: [usersDataList[18]],
  },
  {
    _id: "4",
    name: "Creative Media Group",
    description: "Expertise in digital marketing and media production.",
    email: "contact@creativemediagroup.com",
    phone: "777-888-9999",
    address: "123 Creative Blvd, New York, NY",
    manager: usersDataList[10],
    collaborators: [usersDataList[6]],
  },
  {
    _id: "5",
    name: "FinTech Innovations",
    description: "Revolutionizing financial services with technology.",
    email: "info@fintechinnovations.com",
    phone: "444-555-6666",
    address: "987 Finance Rd, Chicago, IL",
    manager: usersDataList[18],
    collaborators: [usersDataList[9]],
  },
];

export const placesDataList = [
  {
    _id: 1,
    name: "Las Vegas Poker Hall",
    description: "A premier location for all poker enthusiasts.",
    capacity: 100,
    address: "123 Poker St, Las Vegas, NV",
    paying: true,
    price: 10,
    games: [gamesDataList[0], gamesDataList[1]],
    place_reservations: [],
  },
  {
    _id: 2,
    name: "Community Center",
    description: "A cozy place for card games and community gatherings.",
    capacity: 50,
    address: "456 Community St, Anytown, USA",
    paying: false,
    price: null,
    games: [
      gamesDataList[1],
      gamesDataList[2],
      gamesDataList[4],
      gamesDataList[5],
    ],
    place_reservations: [],
  },
  {
    _id: 3,
    name: "Las Vegas Casino",
    description: "The best place for all casino games.",
    capacity: 200,
    address: "456 Casino Rd, Las Vegas, NV",
    paying: true,
    price: 25,
    games: [gamesDataList[1], gamesDataList[2], gamesDataList[3]],
    place_reservations: [],
  },
  {
    _id: 4,
    name: "Family Fun Center",
    description: "A family-friendly venue for games and activities.",
    capacity: 100,
    address: "789 Family Ave, Los Angeles, CA",
    paying: false,
    price: null,
    games: [gamesDataList[2], gamesDataList[4], gamesDataList[5]],
    place_reservations: [],
  },
  {
    _id: 5,
    name: "Gaming Lounge",
    description: "A modern lounge equipped for gaming competitions.",
    capacity: 150,
    address: "321 Gamer St, San Francisco, CA",
    paying: true,
    price: 15,
    games: [gamesDataList[5]],
    place_reservations: [],
  },
];

export const eventsDataList = [
  {
    _id: 1,
    name: "Annual Poker Tournament",
    description: "Join us for an exciting poker tournament with great prizes!",
    status: "open",
    has_banner: true,
    banner: "poker-tournament-banner.jpg",
    has_capacity: true,
    capacity: 50,
    requirement: "none",
    start_date: new Date("2024-05-01T18:00:00Z"),
    end_date: new Date("2025-05-01T22:00:00Z"),
    paying: true,
    price: 20,
    type: "public",
    organized_by: null,
    place_type: "place",
    address: null,
    place_reservation: {
      _id: 1,
      start_date: new Date("2024-11-10T09:00:00Z"),
      end_date: new Date("2024-11-10T17:00:00Z"),
      include_instructors: true,
      event: null,
      place: placesDataList[0],
      company: companiesDataList[0],
    },
    games: [
      gamesDataList[1],
      gamesDataList[2],
      gamesDataList[4],
      gamesDataList[5],
    ],
    participents: [
      usersDataList[9],
      usersDataList[11],
      usersDataList[15],
      usersDataList[19],
    ],
  },
  {
    _id: 2,
    name: "Weekly Bridge Club",
    description: "Join us every Saturday for a fun game of bridge!",
    status: "started",
    has_banner: false,
    banner: "",
    has_capacity: false,
    capacity: null,
    type: "private",
    organized_by: companiesDataList[0],
    place_type: "place",
    place_reservation: {
      _id: 2,
      start_date: new Date("2024-11-15T10:00:00Z"),
      end_date: new Date("2024-11-15T14:00:00Z"),
      include_instructors: false,
      event: null,
      place: placesDataList[1],
      company: companiesDataList[1],
    },
    address: "",
    requirement: "enrolled",
    start_date: new Date("2024-06-01T15:00:00Z"),
    end_date: new Date("2024-06-01T18:00:00Z"),
    paying: false,
    price: null,
    games: [gamesDataList[1], gamesDataList[5]],
    participents: [usersDataList[9], usersDataList[15]],
  },
  {
    _id: 3,
    name: "Blackjack Night",
    description: "Test your luck in our thrilling blackjack night!",
    status: "open",
    has_banner: true,
    banner: "blackjack-night-banner.jpg",
    has_capacity: true,
    capacity: 30,
    type: "public",
    organized_by: null,
    place_type: "local",
    address: "456 Casino Rd, Las Vegas, NV",
    place_reservation: null,
    requirement: "none",
    start_date: new Date("2024-07-15T19:00:00Z"),
    end_date: new Date("2024-07-15T23:00:00Z"),
    paying: true,
    price: 25,
    games: [gamesDataList[3], gamesDataList[4], gamesDataList[5]],
    participents: [usersDataList[11], usersDataList[15]],
  },
  {
    _id: 4,
    name: "Uno Family Game Night",
    description: "Bring the family and enjoy a fun night of Uno!",
    status: "open",
    has_banner: false,
    banner: "",
    has_capacity: true,
    capacity: 20,
    type: "public",
    organized_by: null,
    place_type: "local",
    address: "789 Family Ave, Los Angeles, CA",
    place_reservation: null,
    requirement: "none",
    start_date: new Date("2024-08-10T16:00:00Z"),
    end_date: new Date("2024-08-10T20:00:00Z"),
    paying: false,
    price: null,
    games: [gamesDataList[2], gamesDataList[3], gamesDataList[5]],
    participents: [usersDataList[19]],
  },
  {
    _id: 5,
    name: "Hearthstone Tournament",
    description: "Compete against the best in our Hearthstone tournament!",
    status: "open",
    has_banner: true,
    banner: "hearthstone-tournament-banner.jpg",
    has_capacity: true,
    capacity: 100,
    type: "private",
    organized_by: companiesDataList[1],
    place_type: "local",
    address: "321 Gamer St, San Francisco, CA",
    place_reservation: null,
    requirement: "completed",
    start_date: new Date("2024-09-20T12:00:00Z"),
    end_date: new Date("2024-09-20T18:00:00Z"),
    paying: true,
    price: 15,
    games: [gamesDataList[1], gamesDataList[4], gamesDataList[5]],
    participents: [],
  },
];

export const reservationsDataList = [
  {
    _id: 1,
    start_date: new Date("2024-11-10T09:00:00Z"),
    end_date: new Date("2024-11-10T17:00:00Z"),
    include_instructors: true,
    event: eventsDataList[0],
    place: placesDataList[0],
    company: companiesDataList[0],
  },
  {
    _id: 2,
    start_date: new Date("2024-11-15T10:00:00Z"),
    end_date: new Date("2024-11-15T14:00:00Z"),
    include_instructors: false,
    event: eventsDataList[1],
    place: placesDataList[1],
    company: companiesDataList[1],
  },
  {
    _id: 1,
    start_date: new Date("2024-11-20T08:30:00Z"),
    end_date: new Date("2024-11-20T16:30:00Z"),
    include_instructors: true,
    event: eventsDataList[2],
    place: placesDataList[2],
    company: companiesDataList[2],
  },
];

export const ordersDataList = [
  {
    _id: 1,
    order_number: "ORD123456",
    games: [
      {
        game: gamesDataList[0],
        price: 59.99,
        quantity: 2,
        has_reduction: true,
        total_price: 119.98,
        total_price_after_reduction: 99.98,
      },
      {
        game: gamesDataList[1],
        price: 39.99,
        quantity: 1,
        has_reduction: false,
        total_price: 39.99,
        total_price_after_reduction: 39.99,
      },
    ],
    status: "pending",
    total_price: 159.97,
    total_price_after_reduction: 139.97,
    client: usersDataList[11],
  },
  {
    _id: 2,
    order_number: "ORD987654",
    games: [
      {
        game: gamesDataList[0],
        price: 29.99,
        quantity: 3,
        has_reduction: false,
        total_price: 89.97,
        total_price_after_reduction: 89.97,
      },
      {
        game: gamesDataList[2],
        price: 49.99,
        quantity: 1,
        has_reduction: true,
        total_price: 49.99,
        total_price_after_reduction: 39.99,
      },
    ],
    status: "accepted",
    total_price: 139.96,
    total_price_after_reduction: 129.96,
    client: usersDataList[15],
  },
  {
    _id: 3,
    order_number: "ORD112233",
    games: [
      {
        game: gamesDataList[3],
        price: 19.99,
        quantity: 1,
        has_reduction: false,
        total_price: 19.99,
        total_price_after_reduction: 19.99,
      },
    ],
    status: "delivered",
    total_price: 19.99,
    total_price_after_reduction: 19.99,
    client: usersDataList[19],
  },
];

export const coursesDataList = [
  {
    _id: 1,
    status: "enrolled",
    completed_lessons: [],
    current_lesson: lessonsDataList[3],
    final_test: {
      final_test: finalTestDataList[0],
      status: "not_started",
      score: 0,
      date: null,
    },
    mooc: moocsDataList[0],
  },
  {
    _id: 2,
    status: "final-test",
    completed_lessons: [lessonsDataList[4], lessonsDataList[5]],
    current_lesson: lessonsDataList[6],
    final_test: {
      final_test: finalTestDataList[1],
      status: "failed",
      score: 50,
      date: "2024-12-01T12:00:00Z",
    },
    mooc: moocsDataList[1],
  },
  {
    _id: 3,
    status: "completed",
    completed_lessons: [
      lessonsDataList[7],
      lessonsDataList[8],
      lessonsDataList[9],
    ],
    current_lesson: lessonsDataList[9],
    final_test: {
      final_test: finalTestDataList[2],
      status: "passed",
      score: 90,
      date: "2024-12-02T15:00:00Z",
    },
    mooc: moocsDataList[2],
  },
  {
    _id: 4,
    status: "enrolled",
    completed_lessons: [],
    current_lesson: lessonsDataList[11],
    final_test: {
      final_test: finalTestDataList[3],
      status: "not_started",
      score: 0,
      date: null,
    },
    mooc: moocsDataList[3],
  },
  {
    _id: 5,
    status: "final-test",
    completed_lessons: [lessonsDataList[12], lessonsDataList[13]],
    current_lesson: lessonsDataList[14],
    final_test: {
      final_test: finalTestDataList[4],
      status: "failed",
      score: 40,
      date: "2024-11-30T10:00:00Z",
    },
    mooc: moocsDataList[4],
  },
  {
    _id: 6,
    status: "completed",
    completed_lessons: [
      lessonsDataList[15],
      lessonsDataList[16],
      lessonsDataList[17],
    ],
    current_lesson: lessonsDataList[17],
    final_test: {
      final_test: finalTestDataList[5],
      status: "passed",
      score: 95,
      date: "2024-12-03T18:00:00Z",
    },
    mooc: moocsDataList[5],
  },
];
