import { ArrowBackOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import Builder from "Components/Common/Builder";
import CustomButton from "Components/Common/CustomButton";
import CustomText from "Components/Common/CustomText";
import ErrorText from "Components/Common/ErrorText";
import { moocsDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const FinalTestDetails = () => {
  const navigate = useNavigate();
  const { moocId } = useParams();
  const [finalTest, setFinalTest] = useState(null);
  useEffect(() => {
    const data = moocsDataList.find((mooc) => mooc._id == moocId);
    setFinalTest(data);
  }, [moocId]);
  return (
    <Box>
      <CustomButton
        text="Retour"
        sx={{
          m: "10px",
          color: "black",
        }}
        startIcon={<ArrowBackOutlined style={{ color: "black" }} />}
        onClick={() => navigate(-1)}
      />
      <Builder
        builder={() => {
          if (finalTest) {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    flexDirection: "column",
                    gap: 2,
                    padding: "20px",
                  }}
                >
                  <CustomText
                    sx={{
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                    text={`${finalTest._id}. ${finalTest.title}`}
                  />
                  <CustomText
                    sx={{
                      fontSize: "14px",
                      color: "grey",
                      padding: "10px",
                    }}
                    text={finalTest.description}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px",
                  }}
                ></Box>
              </Box>
            );
          } else {
            return <ErrorText text={"Test final n'existe pas"} />;
          }
        }}
      />
    </Box>
  );
};

export default FinalTestDetails;
