import { Box } from "@mui/material";
import CustomText from "./CustomText";

const TextItem = ({ title, subTitle, sx }) => {
  return (
    <Box
      sx={{
        flex: "1",
        textAlign: "start",
        whiteSpace: "nowrap",
        ...sx,
      }}
    >
      <CustomText
        text={title}
        sx={{
          fontWeight: "bold",
          fontSize: "16px",
        }}
      />
      <CustomText
        text={subTitle}
        sx={{
          fontSize: "14px",
          color: "grey",
        }}
      />
    </Box>
  );
};

export default TextItem;
