import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({
  text,
  onClick,
  disabled,
  endIcon,
  startIcon,
  sx,
  type = "button",
}) => {
  return (
    <Button
      sx={{
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "primary.main",
        fontSize: "14px",
        fontWeight: "400",
        textTransform: "none",
        padding: "5px 15px",
        ...sx,
      }}
      endIcon={endIcon}
      startIcon={startIcon}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
