import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Field } from "formik";
import { useTheme } from "@emotion/react";

const CheckboxGroup = ({
  options,
  value,
  onChange,
  defaultValue,
  getOptionsLabel,
  isChecked,
}) => {
  const theme = useTheme();

  const handleChange = (option) => {
    const newValue = value === option ? defaultValue : option;
    onChange(newValue);
  };

  return (
    <FormGroup>
      {options.map((option) => (
        <FormControlLabel
          key={option}
          control={
            <Checkbox
              checked={isChecked(option)}
              onChange={() => handleChange(option)}
              style={{
                color:
                  value === option
                    ? theme.palette.alt[900]
                    : theme.palette.grey[600],
              }}
            />
          }
          sx={{
            color:
              value === option
                ? theme.palette.alt[900]
                : theme.palette.grey[600],
            ".MuiTypography-root.css-ibwmxy-MuiTypography-root": {
              fontWeight: value === option && "bold",
              fontSize: "14px",
              textWrap: "nowrap",
            },
          }}
          label={getOptionsLabel(option)}
        />
      ))}
    </FormGroup>
  );
};

const CustomCheckBox = ({
  name,
  getOptionsLabel,
  defaultValue,
  options,
  onChange,
}) => {
  return (
    <Field name={name}>
      {({ field }) => (
        <CheckboxGroup
          {...field}
          options={options}
          onChange={(newValue) => {
            field.onChange({
              target: { value: newValue, name: field.name },
            });
            console.log("field", field);
            
            onChange && onChange(newValue);
          }}
          value={field.value}
          defaultValue={defaultValue}
          getOptionsLabel={getOptionsLabel}
          isChecked={(option) => field.value === option}
        />
      )}
    </Field>
  );
};

export default CustomCheckBox;
