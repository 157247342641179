import { Search } from "@mui/icons-material";
import { Box, IconButton, InputBase, Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import image from "Images/logo.png";

const ClientHome = () => {
  const offers = [
    {
      title: "Offre 1",
      description: "Description de l'offre 1",
      image: image,
    },
    {
      title: "Offre 2",
      description: "Description de l'offre 2",
      image: image,
    },
    {
      title: "Offre 3",
      description: "Description de l'offre 3",
      image: image,
    },
    {
      title: "Offre 4",
      description: "Description de l'offre 4",
      image: image,
    },
  ];
  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        overflowY: "auto",
        padding: 1,
      }}
    >
      {/* Search bar */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid grey",
            borderRadius: "15px",
            p: "0.1rem 0.8rem",
            height: "40px",
            width: "40%",
          }}
        >
          <IconButton>
            <Search sx={{ color: "grey" }} />
          </IconButton>
          <InputBase
            id="search-bar"
            name="search-bar"
            placeholder="Recherche..."
            sx={{
              flex: "2",
              color: "grey",
            }}
            onChange={(e) => console.log(e.target.value)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          m: "10px",
        }}
      >
        <Carousel
          autoPlay
          infiniteLoop={true}
          showStatus={false}
          swipeable={true}
          emulateTouch={true}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            return (
              <li
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  margin: "0 10px",
                  listStyle: "none",
                  borderRadius: "50%",
                  backgroundColor: isSelected ? "orange" : "grey",
                  cursor: "pointer",
                }}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                aria-label={`Go to slide ${index}`}
                title={label}
              />
            );
          }}
        >
          {offers.map((offer, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "50vh",
                border: "1px solid orange",
                borderRadius: "10px",
                margin: "0 10px",
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${offer.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "70%",
                  width: "100%",
                }}
              />
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                {offer.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: "#000",
                }}
              >
                {offer.description}
              </Typography>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default ClientHome;
