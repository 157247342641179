import { Box, Button, Typography } from "@mui/material";
import UserItem from "Components/Users/UserItem";
import CustomButton from "Components/Common/CustomButton";
import { usersDataList } from "Pages/data";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PopUp from "Components/Common/Popup";
import UserDetails from "./UserDetails";

const UsersList = () => {
  const navigate = useNavigate();
  const userRoles = ["ADMIN", "CLIENT", "COLLABORATOR", "COMPANY"];
  const [selectedRole, setSelectedRole] = useState(userRoles[0]);
  const [userDetailsOpen, setUserDetailsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const getUserRoleLabel = (role) => {
    const labels = {
      ADMIN: "Admin",
      CLIENT: "Client",
      COLLABORATOR: "Collaborateur",
      COMPANY: "Société",
    };
    return labels[role] || "Role";
  };
  const getFilterUsers = () => {
    return usersDataList.filter((item) => item.role === selectedRole);
  };
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "10px",
        }}
      >
        <Typography variant="h3" fontWeight="bold" color="secondary" p={"10px"}>
          List des utilisateurs
        </Typography>
        <CustomButton
          text="Ajouter"
          sx={{
            width: "100px",
            backgroundColor: "alt.main",
            ":hover": {
              backgroundColor: "alt[400]",
            },
          }}
          onClick={() => navigate(`/users/add`)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          borderTop: "2px solid black",
          flexGrow: "1",
        }}
      >
        {/* Roles */}
        <Box
          sx={{
            width: "220px",
            bgcolor: "secondary.main",
            display: "flex",
            flexDirection: "column",
            borderRadius: "0px 10px 10px 0px",
          }}
        >
          {userRoles.map((item) => (
            <Button
              key={item._id}
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                padding: "10px",
                cursor: "pointer",
                borderRadius: "10px",
                textTransform: "none",
                justifyContent: "start",
                bgcolor: item === selectedRole && "secondary.light",
              }}
              onClick={() => {
                setSelectedRole(item);
              }}
            >
              {getUserRoleLabel(item)}s
            </Button>
          ))}
        </Box>
        {/* Users */}
        <Box
          sx={{
            flexGrow: "1",
            overflow: "auto",
            height: "80vh",
          }}
        >
          {getFilterUsers().map((item) => (
            <UserItem
              key={item._id}
              user={item}
              onClick={() => {
                setSelectedUser(item);
                setUserDetailsOpen(true);
              }}
            />
          ))}
        </Box>
      </Box>
      <PopUp open={userDetailsOpen} setOpen={setUserDetailsOpen}>
        <UserDetails
          user={selectedUser}
          setSelectedUser={setSelectedUser}
          setOpen={setUserDetailsOpen}
        />
      </PopUp>
    </Box>
  );
};

export default UsersList;
