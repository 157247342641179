import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  Grid,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { moocsDataList } from "Pages/data";
import logo from "Images/logo.png";
import { ArrowBackOutlined } from "@mui/icons-material";
import Builder from "Components/Common/Builder";
import { toast } from "react-toastify";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import ErrorText from "Components/Common/ErrorText";

const ClientMoocDetails = () => {
  const navigate = useNavigate();
  const { moocId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [mooc, setMooc] = useState(null);

  useEffect(() => {
    // fetch mooc by id
    // [GET] /user/me/moocs/:moocId
    const fetchMooc = async () => {
      try {
        const data = moocsDataList.find((mooc) => mooc._id == moocId);
        setMooc(data);
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
      }
    };
    fetchMooc();
  }, [moocId]);

  return (
    <Box
      sx={{
        backgroundColor: "#121212",
        height: "calc(100vh - 60px)",
        overflowY: "auto",
        padding: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          marginBottom: 2,
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackOutlined />
        </IconButton>
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Retour
        </Typography>
      </Box>
      <Builder
        builder={() => {
          if (isLoading) {
            return (
              <CustomCircularProgress
                sx={{
                  height: "70%",
                }}
                style={{ color: "#fff" }}
              />
            );
          } else if (mooc) {
            return (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 4,
                    marginBottom: 4,
                  }}
                >
                  {/* Course Image */}
                  <Card
                    sx={{
                      width: "350px",
                      borderRadius: 3,
                      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.5)",
                      overflow: "hidden",
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt={mooc.title}
                      image={logo}
                      sx={{ objectFit: "cover", height: 400 }}
                    />
                  </Card>

                  {/* Course Details */}
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{ fontWeight: "bold", marginBottom: 2 }}
                    >
                      {mooc.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ color: "#555", marginBottom: 2 }}
                    >
                      {mooc.description}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "#777", marginBottom: 1 }}
                    >
                      <strong>Level:</strong> {mooc.game.level}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "#777", marginBottom: 1 }}
                    >
                      <strong>Lesson number:</strong> {mooc.lessons.length}
                    </Typography>

                    <Button
                      variant="contained"
                      size="medium"
                      sx={{
                        backgroundColor: "green",
                        fontWeight: "bold",
                        ":hover": {
                          backgroundColor: "#006400",
                        },
                      }}
                      onClick={() => navigate(`/courses/${mooc._id}`)}
                    >
                      Inscrire maintenant
                    </Button>
                  </Box>
                </Box>
                {/* <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: 3,
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    padding: 4,
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      marginBottom: 3,
                      color: "#121212",
                    }}
                  >
                    What You'll Learn
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#333",
                          fontWeight: "bold",
                          marginBottom: 1,
                        }}
                      >
                        • Game Design Principles
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#333",
                          fontWeight: "bold",
                          marginBottom: 1,
                        }}
                      >
                        • Unity and Unreal Basics
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#333",
                          fontWeight: "bold",
                          marginBottom: 1,
                        }}
                      >
                        • Programming for Games
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#333",
                          fontWeight: "bold",
                          marginBottom: 1,
                        }}
                      >
                        • Testing and Publishing Games
                      </Typography>
                    </Grid>
                  </Grid>
                </Box> */}
              </Box>
            );
          } else {
            return (
              <ErrorText text="Mooc non trouvé" style={{ color: "#fff" }} />
            );
          }
        }}
      />
    </Box>
  );
};

export default ClientMoocDetails;
