import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import React from "react";
import '@leenguyen/react-flip-clock-countdown/dist/index.css';


const CustomCountDown = ({ end_date }) => {
  return (
    <FlipClockCountdown
      to={end_date}
      digitBlockStyle={{
        height: "30px",
        width: "20px",
        fontSize: "15px",
        fontWeight: "bold",
      }}
      separatorStyle={{
        size: "3px",
      }}
      labelStyle={{
        fontSize: "10px",
        fontWeight: "bold",
      }}
      labels={["J", "H", "M", "S"]}
    />
  );
};

export default CustomCountDown;
