import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
  BorderColorOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Builder from "Components/Common/Builder";
import CustomButton from "Components/Common/CustomButton";
import CustomText from "Components/Common/CustomText";
import ErrorText from "Components/Common/ErrorText";
import ReservationCompanyDetails from "Components/Reservations/ReservationCompanyDetails";
import ReservationPlaceDetails from "Components/Reservations/ReservationPlaceDetails";
import TextItem from "Components/Common/TextItem";
import { formatDate } from "helpers/format_date";
import { reservationsDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ReservationDetails = () => {
  const navigate = useNavigate();
  const { reservationId } = useParams();
  const [reservation, setReservation] = useState(null);
  useEffect(() => {
    const data = reservationsDataList.find((item) => item._id == reservationId);
    setReservation(data);
  }, [reservationId]);
  return (
    <Builder
      builder={() => {
        if (reservation) {
          return (
            <Box>
              {/* Header */}
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: "20px",
                }}
              > */}
              <Button
                startIcon={
                  <ArrowBackOutlined
                    sx={{
                      color: "grey",
                      height: "15px",
                      width: "18px",
                    }}
                  />
                }
                sx={{
                  m: "10px",
                  color: "grey",
                  textTransform: "none",
                  fontSize: "14px",
                }}
                onClick={() => navigate(-1)}
              >
                Retour
              </Button>
              {/* Body */}
              <Box
                sx={{
                  p: "20px",
                }}
              >
                {/* Details */}
                <Box
                  sx={{
                    p: "10px",
                    border: "1px solid black",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "grey",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      À propos de l'événement
                    </Typography>
                    <CustomButton
                      text={"Voir plus"}
                      sx={{
                        bgcolor: "blue",
                        p: "2px 10px",
                      }}
                      onClick={() =>
                        navigate(`/events/${reservation.event._id}`)
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "70px",
                      rowGap: "20px",
                      padding: "10px",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                  >
                    <TextItem title="Nom" subTitle={reservation.event.name} />

                    <TextItem
                      title="Date de debut"
                      subTitle={formatDate(reservation.event.start_date)}
                    />
                    <TextItem
                      title="Date de fin"
                      subTitle={formatDate(reservation.event.end_date)}
                    />
                    <TextItem
                      title="Description"
                      subTitle={reservation.event.description}
                    />
                  </Box>
                </Box>
                {/* company and place */}
                <Box
                  sx={{
                    height: "40vh",
                    display: "flex",
                    mt: "20px",
                    gap: "10px",
                  }}
                >
                  {/* company */}
                  <ReservationCompanyDetails
                    companyId={reservation.company._id}
                  />
                  {/* place */}
                  <ReservationPlaceDetails placeId={reservation.place._id} />
                </Box>
              </Box>
            </Box>
          );
        } else {
          return <ErrorText text={"Reservation n'existe pas"} />;
        }
      }}
    />
  );
};

export default ReservationDetails;
