// reducers/cartReducer.js
import { ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART, UPDATE_QUANTITY } from "../Actions/CartActions"

const initialState = {
  items: [], // Array of cart items
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      // Check if the item is already in the cart
      const existingItemIndex = state.items.findIndex((item) => item.id === action.payload.id);
      if (existingItemIndex !== -1) {
        // If item exists, increase the quantity
        const updatedItems = [...state.items];
        updatedItems[existingItemIndex].quantity += 1;
        return { ...state, items: updatedItems };
      }
      // If item does not exist, add it with quantity 1
      return { ...state, items: [...state.items, { ...action.payload, quantity: 1 }] };

    case REMOVE_FROM_CART:
      // Filter out the item to be removed
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      };

    case CLEAR_CART:
      // Clear the cart
      return { ...state, items: [] };

    case UPDATE_QUANTITY:
      // Find and update the quantity of the specified item
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.payload.itemId
            ? { ...item, quantity: action.payload.quantity }
            : item
        ),
      };

    default:
      return state;
  }
};

export default cartReducer;
