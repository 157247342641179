import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import ClientGamesList from "./ClientGamesList";
import ClientGameDetails from "./ClientGameDetails";
import NotFoundError from "Components/Common/NotFoundError";

const ClientGames = () => {
  return (
    <Box>
      <Routes>
        <Route path="/" element={<ClientGamesList />} />
        <Route path="/:gameId" element={<ClientGameDetails />} />
        <Route path="*" element={<NotFoundError />} />
      </Routes>
    </Box>
  );
};

export default ClientGames;
