import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import image from "Images/logo.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../Redux/Actions/CartActions";
import Builder from "Components/Common/Builder";
import { updateQuantity } from "../../../Redux/Actions/CartActions";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const ClientGameCard = ({ game }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.cart);

  const handleAddToCart = () => {
    dispatch(addToCart({ id: game._id, name: game.name, price: game.price }));
  };

  return (
    <Card
      sx={{
        height: "270px",
        backgroundColor: "#1e1e1e",
        color: "#fff",
        borderRadius: 2,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
        maxWidth: 280,
        margin: 2,
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        ":hover": {
          transform: "scale(1.05)",
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.5)",
        },
        cursor: "pointer",
      }}
      onClick={() => navigate(`/games/${game._id}`)}
    >
      <CardMedia
        component="img"
        height="140"
        image={image}
        alt={game.name}
        sx={{ borderRadius: "8px 8px 0 0" }}
      />
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {game.name}
        </Typography>
        <Typography variant="body2" color="#aaa">
          {game.level}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop={2}
          gap={2}
        >
          <Typography variant="h5" color="orange" fontWeight="bold">
            {game.price} DT
          </Typography>
          <Builder
            builder={() => {
              const item = items.find((item) => item.id === game._id);
              if (item) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      marginTop: 1,
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(updateQuantity(item.id, item.quantity - 1));
                      }}
                      disabled={item.quantity <= 1}
                      sx={{ color: "orange" }}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <Typography variant="body1">{item.quantity}</Typography>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(updateQuantity(item.id, item.quantity + 1));
                      }}
                      sx={{ color: "orange" }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                );
              } else {
                return (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "orange",
                      color: "#fff",
                      fontWeight: "bold",
                      ":hover": {
                        backgroundColor: "#e69500",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent card click event
                      handleAddToCart();
                    }}
                  >
                    Add to Cart
                  </Button>
                );
              }
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default ClientGameCard;
