import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  Divider,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import logo from "Images/logo.png";
import { ArrowBackOutlined } from "@mui/icons-material";
import Builder from "Components/Common/Builder";
import CustomCountDown from "Components/Common/CustomCountDown";
import { coursesDataList } from "Pages/data";
import { formatDate } from "helpers/format_date";
import { toast } from "react-toastify";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import Each from "Components/Common/Each";
import ErrorText from "Components/Common/ErrorText";

const ClientCourseDetails = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const data = coursesDataList.find((course) => course._id == courseId);
        setCourse(data);
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
      }
    };
    fetchCourse();
  }, [courseId]);

  return (
    <Box
      sx={{
        backgroundColor: "#121212",
        color: "#fff",
        padding: 2,
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          marginBottom: 2,
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackOutlined />
        </IconButton>
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Retour
        </Typography>
      </Box>
      {/* Hero Section */}
      <Builder
        builder={() => {
          if (isLoading) {
            return (
              <CustomCircularProgress
                sx={{
                  height: "70%",
                }}
                style={{ color: "#fff" }}
              />
            );
          } else if (course) {
            return (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 4,
                    alignItems: { xs: "center", md: "flex-start" },
                  }}
                >
                  {/* Game Image */}
                  <Card
                    sx={{
                      maxWidth: "400px",
                      borderRadius: 3,
                      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.5)",
                      overflow: "hidden",
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt={course.mooc.title}
                      image={logo}
                      sx={{ objectFit: "cover", height: 400 }}
                    />
                  </Card>

                  {/* Game Details */}
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="h3"
                      sx={{ fontWeight: "bold", marginBottom: 2 }}
                    >
                      {course.mooc.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="#aaa"
                      sx={{ marginBottom: 2 }}
                    >
                      {course.description}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="#aaa"
                      sx={{ marginBottom: 1 }}
                    >
                      <strong>Jeux:</strong> {course.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="#aaa"
                      sx={{ marginBottom: 1 }}
                    >
                      <strong>Progression:</strong>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      {course.completed_lessons.map((lesson) => {})}
                      <Each
                        of={course.completed_lessons}
                        render={(lesson) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #333",
                              padding: 2,
                              borderRadius: 3,
                            }}
                          >
                            <Typography variant="body2" color="#aaa">
                              {lesson.title}
                            </Typography>
                          </Box>
                        )}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        marginTop: 3,
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "orange",
                          color: "#fff",
                          fontWeight: "bold",
                          ":hover": {
                            backgroundColor: "#e69500",
                          },
                        }}
                        onClick={() => {
                          navigate(
                            `/courses/${course._id}/lessons/${course.current_lesson._id}`
                          );
                        }}
                      >
                        Continuer
                      </Button>
                    </Box>
                  </Box>
                </Box>

                <Divider sx={{ marginY: 4, borderColor: "#333" }} />
                <Box>
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", marginBottom: 3 }}
                  >
                    A propos de mooc
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#aaa"
                    sx={{ marginBottom: 2 }}
                  >
                    {course.description}
                  </Typography>
                </Box>
              </Box>
            );
          } else {
            return (
              <ErrorText text="Cours non trouvé" style={{ color: "#fff" }} />
            );
          }
        }}
      />
    </Box>
  );
};

export default ClientCourseDetails;
