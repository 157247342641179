import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import ClientCoursesList from "./ClientCoursesList";
import ClientCourseDetails from "./ClientCourseDetails";
import NotFoundError from "Components/Common/NotFoundError";
import ClientLessonDetails from "../Lessons/ClientLessonDetails";

const ClientCourses = () => {
  return (
    <Box>
      <Routes>
        <Route path="/" element={<ClientCoursesList />} />
        <Route path="/:courseId/*">
          <Route path="" element={<ClientCourseDetails />} />
          <Route path="lessons" element={<>lessons</>} />
          <Route path="lessons/:lessonId" element={<ClientLessonDetails />} />
          <Route path="final-test" element={<>final test</>} />
          <Route path="certificate" element={<>certificate</>} />
          <Route path="*" element={<NotFoundError />} />
        </Route>
        <Route path="*" element={<NotFoundError />} />
      </Routes>
    </Box>
  );
};

export default ClientCourses;
