import {
  AddOutlined,
  ArrowBackOutlined,
  ArrowForwardOutlined,
  DeleteOutline,
  DeleteOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { Box, Button, Checkbox, IconButton, Typography } from "@mui/material";
import Builder from "Components/Common/Builder";
import CustomButton from "Components/Common/CustomButton";
import CustomField2 from "Components/Common/CustomField2";
import CustomText from "Components/Common/CustomText";
import Each from "Components/Common/Each";
import ErrorText from "Components/Common/ErrorText";
import PopUp from "Components/Common/Popup";
import { Form, Formik } from "formik";
import { moocsDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const QuestionItem = ({ question, index, setOpen, setQuestion }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        padding: "10px",
        border: "1px solid #000",
        borderRadius: "5px",
      }}
    >
      <CustomText
        sx={{
          fontSize: "18px",
          fontWeight: "bold",
        }}
        text={`${index + 1}. ${question.text}`}
      />
      <Each
        of={question.options}
        render={(option, index) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <CustomText
              sx={{
                fontSize: "14px",
              }}
              text={option.option}
            />
            {option.is_correct ? (
              <CustomText
                sx={{
                  fontSize: "14px",
                  color: "green",
                }}
                text="Correct"
              />
            ) : null}
          </Box>
        )}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          gap: 2,
        }}
      >
        <IconButton
          sx={{
            color: "blue",
          }}
          onClick={() => {
            setQuestion(question);
            setOpen(true);
          }}
        >
          <EditOutlined />
        </IconButton>
        <IconButton
          sx={{
            color: "red",
          }}
          onClick={() => {}}
        >
          <DeleteOutlined />
        </IconButton>
      </Box>
    </Box>
  );
};

const OptionItem = ({
  option,
  handleRemoveOption,
  handleUpdateOption,
  handleCheckOption,
  optionIndex,
}) => {
  return (
    <Box
      sx={{
        m: "10px 0",
      }}
    >
      <CustomText
        sx={{
          fontSize: "14px",
          color: "grey",
          mt: "20px",
        }}
        text={"Option"}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <CustomField2
          name={optionIndex}
          sx={{ flexGrow: "1" }}
          value={option.option}
          onChange={(e) => handleUpdateOption(optionIndex, e.target.value)}
        />
        <IconButton
          sx={{
            bgcolor: "black",
            borderRadius: "9px",
            ":hover": {
              bgcolor: "black",
            },
          }}
          onClick={() => handleRemoveOption(optionIndex)}
        >
          <DeleteOutline />
        </IconButton>
        <Checkbox
          color="secondary"
          sx={{
            color: "black",
          }}
          checked={option.is_correct}
          onChange={() => handleCheckOption(optionIndex)}
        />
      </Box>
    </Box>
  );
};

const AddEditQuestion = ({ selectedQuestion, setOpen }) => {
  const [question, setQuestion] = useState(
    selectedQuestion || {
      text: "",
      options: [],
    }
  );
  const handleSubmit = () => {
    try {
    } catch (error) {
      toast.error("Erreur");
    }
  };

  const handleAddOption = () => {
    setQuestion((prev) => {
      const options = [...prev.options, { option: "", is_correct: false }];
      return { ...prev, options };
    });
  };

  const handleRemoveOption = (optionIndex) => {
    setQuestion((prev) => {
      const options = prev.options.filter((_, index) => index !== optionIndex);
      return { ...prev, options };
    });
  };

  const handleUpdateOption = (optionIndex, value) => {
    setQuestion((prev) => {
      const options = prev.options.map((option, index) =>
        index === optionIndex ? { ...option, option: value } : option
      );
      return { ...prev, options };
    });
  };

  const handleCheckOption = (optionIndex) => {
    setQuestion((prev) => {
      const options = prev.options.map((option, index) =>
        index === optionIndex
          ? { ...option, is_correct: !option.is_correct }
          : option
      );
      return { ...prev, options };
    });
  };

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={handleSubmit}
      initialValues={{}}
      validateOnMount={true}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Form onSubmit={(e) => handleSubmit(e)}>
          {/* Header */}
          <Box
            sx={{
              bgcolor: "white",
              p: "20px 10px",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                p: "20px 10px",
                backgroundColor: "white",
                position: "sticky",
                top: "0",
                zIndex: "1",
              }}
            >
              <Typography variant="h3" fontWeight="bold" color="secondary">
                {question ? "Modifier" : "Ajouter"} une question
              </Typography>
            </Box>
            <Box
              sx={{
                p: "20px 20px 35px 15px",
              }}
            >
              <Box
                sx={{
                  m: "20px 0",
                }}
              >
                <CustomText
                  sx={{
                    fontSize: "14px",
                    color: "grey",
                    mt: "20px",
                  }}
                  text={"Question"}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <CustomField2
                    name={"text"}
                    sx={{ flexGrow: "1" }}
                    value={question?.text}
                    onChange={(e) =>
                      setQuestion({ ...question, text: e.target.value })
                    }
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "grey",
                    mt: "20px",
                  }}
                >
                  Options
                </Typography>
                <Each
                  of={question.options}
                  render={(option, index) => (
                    <OptionItem
                      option={option}
                      handleRemoveOption={handleRemoveOption}
                      handleCheckOption={handleCheckOption}
                      handleUpdateOption={handleUpdateOption}
                      optionIndex={index}
                    />
                  )}
                />
                <CustomButton
                  text={"Ajouter une option"}
                  onClick={() => handleAddOption()}
                  sx={{
                    bgcolor: "black",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "space-around",
              }}
            >
              <Button
                sx={{
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: "400",
                  backgroundColor: "primary.main",
                  color: "alt.main",
                  border: "1px solid",
                  borderRadius: "4px",
                  borderColor: "grey.light",
                  textTransform: "none",
                  ":hover": {
                    backgroundColor: "primary.main",
                  },
                }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                Annuler
              </Button>
              <Button
                sx={{
                  width: "100px",
                  fontSize: "16px",
                  fontWeight: "400",
                  backgroundColor: "alt.main",
                  textTransform: "none",
                  ":hover": {
                    backgroundColor: "alt.main",
                  },
                }}
                type="submit"
              >
                Confirmer
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const LessonDetails = () => {
  const navigate = useNavigate();
  const { moocId, lessonId } = useParams();
  const [lesson, setLesson] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  useEffect(() => {
    const mooc = moocsDataList.find((mooc) => mooc._id == moocId);
    if (mooc) {
      const data = mooc.lessons.find((item) => item._id == lessonId);
      setLesson(data);
    }
  }, [lessonId, moocId]);

  const handleAddQuestion = () => {
    try {
    } catch (error) {
      toast.error("Erreur lors de l'ajout de la question");
    }
  };

  const handleEditQuestion = () => {
    try {
    } catch (error) {
      toast.error("Erreur lors de la modification de la question");
    }
  };

  const handleDeleteQuestion = () => {
    try {
    } catch (error) {
      toast.error("Erreur lors de la suppression de la question");
    }
  };
  return (
    <Box>
      <CustomButton
        text="Retour"
        sx={{
          m: "10px",
          color: "black",
        }}
        startIcon={<ArrowBackOutlined style={{ color: "black" }} />}
        onClick={() => navigate(-1)}
      />
      <Builder
        builder={() => {
          if (lesson) {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    flexDirection: "column",
                    gap: 2,
                    padding: "20px",
                  }}
                >
                  <CustomText
                    sx={{
                      fontSize: "24px",
                      fontWeight: "bold",
                    }}
                    text={`${lesson._id}. ${lesson.title}`}
                  />
                  <CustomText
                    sx={{
                      fontSize: "14px",
                      color: "grey",
                      padding: "10px",
                    }}
                    text={lesson.description}
                  />
                  <CustomText
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                    text="Questions"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #000",
                      borderRadius: "5px",
                      flex: "1",
                      width: "100%",
                    }}
                  >
                    <IconButton
                      sx={{
                        color: "grey",
                        height: "100%",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        setSelectedQuestion(null);
                        setOpen(true);
                      }}
                    >
                      <AddOutlined
                        sx={{
                          fontSize: "50px",
                          borderRadius: "5px",
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 2,
                    }}
                  >
                    <Each
                      of={lesson.questions}
                      render={(question, index) => (
                        <QuestionItem
                          question={question}
                          index={index}
                          setOpen={setOpen}
                          setQuestion={setSelectedQuestion}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px",
                  }}
                >
                  <CustomButton
                    text="Précédent"
                    sx={{
                      bgcolor: "primary.main",
                      borderColor: lesson.previous_lesson ? "#000" : "grey",
                      margin: "10px",
                      color: "#000",
                      ":disabled": {
                        color: "grey",
                      },
                    }}
                    startIcon={
                      <ArrowBackOutlined
                        style={{
                          color: lesson.previous_lesson ? "#000" : "grey",
                        }}
                      />
                    }
                    disabled={!lesson.previous_lesson}
                    onClick={() =>
                      navigate(
                        `/moocs/${moocId}/lessons/${lesson.previous_lesson}`
                      )
                    }
                  />
                  <CustomButton
                    text="Suivant"
                    sx={{
                      bgcolor: "primary.main",
                      borderColor: lesson.next_lesson ? "#000" : "grey",
                      margin: "10px",
                      color: "#000",
                      ":disabled": {
                        color: "grey",
                      },
                    }}
                    endIcon={
                      <ArrowForwardOutlined
                        style={{ color: lesson.next_lesson ? "#000" : "grey" }}
                      />
                    }
                    disabled={!lesson.next_lesson}
                    onClick={() =>
                      navigate(`/moocs/${moocId}/lessons/${lesson.next_lesson}`)
                    }
                  />
                </Box>
                <PopUp open={open} setOpen={setOpen}>
                  <AddEditQuestion
                    setOpen={setOpen}
                    selectedQuestion={selectedQuestion}
                  />
                </PopUp>
              </Box>
            );
          } else {
            return <ErrorText text={"leçon n'existe pas"} />;
          }
        }}
      />
    </Box>
  );
};

export default LessonDetails;
