import { Box } from "@mui/material";
import { Outlet } from "react-router";
import ClientNavbar from "Components/Client/NavBar/NavBar";

const ClientLayout = () => {
  return (
    <Box>
      <ClientNavbar />
      <Outlet />
    </Box>
  );
};

export default ClientLayout;
