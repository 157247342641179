import { Box, Typography } from "@mui/material";
import Builder from "Components/Common/Builder";
import ErrorText from "Components/Common/ErrorText";
import LoadingShimmer from "Components/Common/LoadingShimmer";
import TextItem from "Components/Common/TextItem";
import { usersDataList } from "Pages/data";
import React, { useEffect, useState } from "react";

const ClientDetails = ({ clientId }) => {
  const [client, setClient] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const data = usersDataList.find((item) => item._id == clientId);
    if (data) {
      setClient(data);
    }
  }, [clientId]);
  return (
    <Box
      sx={{
        border: "1px solid black",
        borderRadius: "6px",
        pt: "20px",
        flex: "1",
        overflowY: "auto",
      }}
    >
      <Typography
        fontSize="20px"
        fontWeight="bold"
        color={"grey"}
        sx={{
          pl: "15px",
        }}
      >
        Client
      </Typography>

      <Builder
        builder={() => {
          if (isLoading) {
            return <LoadingShimmer />;
          }
          if (client) {
            return (
              <Box>
                <TextItem
                  title="Nom"
                  subTitle={`${client.first_name} ${client.last_name}`}
                />
                <TextItem title="Email" subTitle={client.email} />
                <TextItem title="Téléphone" subTitle={client.phone} />
                <TextItem title="Adress" subTitle={client.address} />
              </Box>
            );
          } else {
            return (
              <ErrorText
                text={"Gestionnaire non trouvé"}
                sx={{ height: "80%" }}
              />
            );
          }
        }}
      />
    </Box>
  );
};

export default ClientDetails;
