import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import PlacesList from "./PlacesList";
import AddEditPlace from "./AddEditPlace.jsx";
import PlaceDetails from "./PlaceDetails";
import NotFoundError from "Components/Common/NotFoundError";

const Places = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Routes>
        <Route path="/" element={<PlacesList />} />
        <Route path="/add" element={<AddEditPlace />} />
        <Route path="/:placeId/edit" element={<AddEditPlace />} />
        <Route path="/:placeId" element={<PlaceDetails />} />
        <Route path="*" element={<NotFoundError />} />
      </Routes>
    </Box>
  );
};

export default Places;
