import { Box, Typography } from "@mui/material";
import Builder from "Components/Common/Builder";
import ErrorText from "Components/Common/ErrorText";
import LoadingShimmer from "Components/Common/LoadingShimmer";
import TextItem from "Components/Common/TextItem";
import { companiesDataList } from "Pages/data";
import React, { useEffect, useState } from "react";

const ManagerDetails = ({ companyId }) => {
  const [manager, setManager] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const data = companiesDataList.find((item) => item._id == companyId);
    if (data) {
      setManager(data.manager);
    }
  }, [companyId]);
  return (
    <Box
      sx={{
        border: "1px solid black",
        borderRadius: "6px",
        pt: "20px",
        flex: "1",
        overflowY: "auto",
      }}
    >
      <Typography
        fontSize="20px"
        fontWeight="bold"
        color={"grey"}
        sx={{
          pl: "15px",
        }}
      >
        Gestionnaire
      </Typography>

      <Builder
        builder={() => {
          if (isLoading) {
            return <LoadingShimmer />;
          }
          if (manager) {
            return (
              <Box>
                <TextItem
                  title="Nom"
                  subTitle={`${manager.first_name} ${manager.last_name}`}
                />
                <TextItem title="Email" subTitle={manager.email} />
                <TextItem title="Téléphone" subTitle={manager.phone} />
                <TextItem title="Adress" subTitle={manager.address} />
              </Box>
            );
          } else {
            return (
              <ErrorText
                text={"Gestionnaire non trouvé"}
                sx={{ height: "80%" }}
              />
            );
          }
        }}
      />
    </Box>
  );
};

export default ManagerDetails;
