import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomField from "Components/Common/CustomField";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import CustomDropDown from "Components/Common/CustomDropDown";
import CustomDatePicker from "Components/Common/CustomDatePicker";
import dayjs from "dayjs";
import Builder from "Components/Common/Builder";
import { companiesDataList, gamesDataList, placesDataList } from "Pages/data";
import CustomAutoComplete from "Components/Common/CustomAutoComplete";

const eventStatus = ["open", "closed", "started", "finished"];
const eventTypes = ["public", "private"];
const eventPlaceTypes = ["local", "place"];
const eventJoinRequirements = ["none", "enrolled", "final-test", "completed"];

const getEventSTatusLabel = (status) => {
  const statusLabels = {
    open: "Ouvert",
    closed: "Fermé",
    started: "Commencé",
    finished: "Terminé",
  };
  return statusLabels[status] || "Ouvert";
};

const getEventTypeLabel = (type) => {
  const typeLable = {
    public: "Public",
    private: "Privé",
  };
  return typeLable[type] || "Public";
};

const getEventPlaceTypeLabel = (placeType) => {
  const placeTypeLabel = {
    local: "Local",
    place: "Réservation de lieu",
  };
  return placeTypeLabel[placeType] || "Local";
};

const getEventJoinRequirementLabel = (requirement) => {
  const requirementLabel = {
    none: "Aucun",
    enrolled: "Inscrit dans le Mooc",
    "final-test": "Test final",
    completed: "Terminé le Mooc",
  };
  return requirementLabel[requirement] || "Aucun";
};

const AddEditEvent = () => {
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const companies = [];

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (eventId) {
      const fetchEventData = async () => {
        try {
          setIsLoading(true);
          // Fetch and set event data if editing an existing event
        } catch (error) {
          toast.error(`Erreur : ${error.message}`);
        } finally {
          setIsLoading(false);
        }
      };
      fetchEventData();
    }
  }, [eventId]);

  const handleFileChange = useCallback((e, setFieldValue) => {
    const file = e.target.files[0];
    setFieldValue("selectedFile", file);
  }, []);

  const handleDeleteFile = useCallback((setFieldValue) => {
    setFieldValue("selectedFile", null);
  }, []);

  const searchOptions = (options, state) => {
    return options.filter((option) =>
      option.name.toLowerCase().includes(state.inputValue.toLowerCase())
    );
  };

  const onSubmit = async (values) => {
    const {
      name,
      description,
      capacity,
      type,
      price,
      start_date,
      end_date,
      games,
      requirement,
      place,
      place_type,
      address,
      selectedFile,
    } = values;
    const data = {
      name,
      description,
      has_capacity: capacity && capacity > 0,
      capacity,
      type,
      paying: price && price > 0,
      price,
      start_date,
      end_date,
      games,
      requirement,
      place,
      place_type,
      address,
      has_banner: selectedFile !== null,
      banner: selectedFile,
    };

    try {
      if (eventId) {
        navigate("/events");
      } else {
        // await dispatch(createNewEvent(data));
        toast.success("Jeu ajouté avec succès!");
      }
    } catch (error) {
      toast.error(`Erreur : ${error.message}`);
    }
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={{
        name: "",
        description: "",
        status: eventStatus[0],
        has_banner: false,
        banner: null,
        has_capacity: false,
        capacity: null,
        type: eventTypes[0],
        organized_by: null,
        place_type: eventPlaceTypes[0],
        address: "",
        place: null,
        requirement: eventJoinRequirements[0],
        start_date: new Date(),
        end_date: new Date(),
        paying: false,
        price: null,
        games: [],
        banner: null,
      }}
      validateOnMount={true}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Box>
          {isLoading ? (
            <CustomCircularProgress />
          ) : (
            <Form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
                  backgroundColor: theme.palette.primary.main,
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <Typography variant="h3" fontWeight="bold" color="secondary">
                  {eventId ? "Modifier l'" : "Nouveau "}événement
                </Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate(-1)}
                    type="button"
                  >
                    Annuler
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    {eventId ? "Modifier" : "Ajouter"}
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: theme.palette.grey[100],
                  borderTopLeftRadius: "9px",
                  borderTopRightRadius: "9px",
                }}
              >
                <Typography variant="h5" fontWeight="bold" color="secondary">
                  Informations Générales
                </Typography>
                <CustomField name="name" type="text" title="Nom d'événement" />
                <CustomField
                  name="description"
                  type="text"
                  title="Description"
                  multiline
                  rows={4}
                />
                <CustomField name="price" type="number" title="Prix" />
                <CustomField name="capacity" type="number" title="Capacité" />
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <CustomDatePicker
                    name="begin_date"
                    title="Date de début"
                    setFieldValue={setFieldValue}
                    value={dayjs(values.begin_date)}
                  />
                  <CustomDatePicker
                    name="end_date"
                    title="Date de fin"
                    setFieldValue={setFieldValue}
                    value={dayjs(values.end_date)}
                  />
                </Box>
                <Typography variant="h6" color={theme.palette.grey[600]} mb={1}>
                  Exigence
                </Typography>
                <CustomDropDown
                  name="requirement"
                  value={values.requirement}
                  items={eventJoinRequirements}
                  getItems={getEventJoinRequirementLabel}
                />
                <CustomAutoComplete
                  hint={"Jeux"}
                  name={"games"}
                  options={gamesDataList}
                  value={values.games}
                  toggleOption={(value) => setFieldValue("games", value)}
                  filterOptions={searchOptions}
                />
                <Typography variant="h6" color={theme.palette.grey[600]} mb={1}>
                  Type d'événement
                </Typography>
                <CustomDropDown
                  name="type"
                  value={values.type}
                  items={eventTypes}
                  getItems={getEventTypeLabel}
                />
                <Builder
                  builder={() => {
                    if (values.type === "private") {
                      return (
                        <>
                          <Typography
                            variant="h6"
                            color={theme.palette.grey[600]}
                            mb={1}
                          >
                            Organisateur
                          </Typography>
                          <CustomDropDown
                            name="organized_by"
                            value={values.organized_by}
                            items={companiesDataList}
                            getItems={(item) => item.name}
                          />
                        </>
                      );
                    }
                  }}
                />
                <Builder
                  builder={() => {
                    if (eventId) {
                      return (
                        <>
                          <Typography
                            variant="h6"
                            color={theme.palette.grey[600]}
                            mb={1}
                          >
                            Status d'événement
                          </Typography>
                          <CustomDropDown
                            name="status"
                            value={values.status}
                            items={eventStatus}
                            getItems={getEventSTatusLabel}
                          />
                        </>
                      );
                    }
                  }}
                />
                <Typography variant="h6" color={theme.palette.grey[600]} mb={1}>
                  Type de lieu
                </Typography>
                <CustomDropDown
                  name="place_type"
                  value={values.place_type}
                  items={eventPlaceTypes}
                  getItems={getEventPlaceTypeLabel}
                />
                <Builder
                  builder={() => {
                    if (values.place_type === "local") {
                      return (
                        <CustomField
                          name="address"
                          type="text"
                          title="Adress"
                        />
                      );
                    } else {
                      return (
                        <>
                          <Typography
                            variant="h6"
                            color={theme.palette.grey[600]}
                            mb={1}
                          >
                            Lieux
                          </Typography>
                          <CustomDropDown
                            name="place"
                            value={values.place}
                            items={placesDataList}
                            getItems={(item) => item.name}
                          />
                        </>
                      );
                    }
                  }}
                />
                <Box mt={3}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      color: "black",
                    }}
                  >
                    Ajouter une bannière
                  </Typography>
                  <Box display="flex" alignItems="center">
                    {values.selectedFile ? (
                      <Box position="relative">
                        <img
                          src={URL.createObjectURL(values.selectedFile)}
                          alt="Prévisualisation"
                          width={80}
                          height={80}
                          style={{ borderRadius: 8 }}
                        />
                        <Button
                          size="small"
                          onClick={() => handleDeleteFile(setFieldValue)}
                          sx={{
                            position: "absolute",
                            top: -8,
                            right: -8,
                            minWidth: "auto",
                            p: 0.5,
                            color: "black",
                          }}
                        >
                          X
                        </Button>
                      </Box>
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={() => fileInputRef.current.click()}
                        sx={{
                          color: "black",
                          borderColor: "black",
                        }}
                      >
                        Ajouter
                      </Button>
                    )}
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, setFieldValue)}
                    />
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Box>
      )}
    </Formik>
  );
};

export default AddEditEvent;
