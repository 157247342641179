import { useTheme } from "@emotion/react";
import { DeleteOutline } from "@mui/icons-material";
import { Box, Button, Checkbox, IconButton, Typography } from "@mui/material";
import CustomButton from "Components/Common/CustomButton";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import CustomField from "Components/Common/CustomField";
import CustomField2 from "Components/Common/CustomField2";
import CustomText from "Components/Common/CustomText";
import Each from "Components/Common/Each";
import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const OptionItem = ({
  option,
  handleRemoveOption,
  handleUpdateOption,
  handleCheckOption,
  questionIndex,
  optionIndex,
}) => {
  return (
    <Box
      sx={{
        m: "10px 0",
      }}
    >
      <CustomText
        sx={{
          fontSize: "14px",
          color: "grey",
          mt: "20px",
        }}
        text={"Option"}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <CustomField2
          name={`${questionIndex}-${optionIndex}`}
          sx={{ flexGrow: "1" }}
          value={option.option}
          onChange={(e) =>
            handleUpdateOption(questionIndex, optionIndex, e.target.value)
          }
        />
        <IconButton
          sx={{
            bgcolor: "black",
            borderRadius: "9px",
            ":hover": {
              bgcolor: "black",
            },
          }}
          onClick={() => handleRemoveOption(questionIndex, optionIndex)}
        >
          <DeleteOutline />
        </IconButton>
        <Checkbox
          color="secondary"
          sx={{
            color: "black",
          }}
          checked={option.is_correct}
          onChange={() => handleCheckOption(questionIndex, optionIndex)}
        />
      </Box>
    </Box>
  );
};

const QuestionItem = ({
  item,
  handleAddOption,
  handleRemoveQuestion,
  handleRemoveOption,
  handleCheckOption,
  handleUpdateQuestion,
  handleUpdateOption,
  questionIndex,
}) => {
  return (
    <Box
      sx={{
        m: "20px 0",
      }}
    >
      <CustomText
        sx={{
          fontSize: "14px",
          color: "grey",
          mt: "20px",
        }}
        text={"Question"}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <CustomField2
          name={questionIndex}
          sx={{ flexGrow: "1" }}
          value={item.text}
          onChange={(e) => handleUpdateQuestion(questionIndex, e.target.value)}
        />
        <IconButton
          sx={{
            bgcolor: "black",
            borderRadius: "9px",
            ":hover": {
              bgcolor: "black",
            },
          }}
          onClick={() => handleRemoveQuestion(questionIndex)}
        >
          <DeleteOutline />
        </IconButton>
      </Box>
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "bold",
          color: "grey",
          mt: "20px",
        }}
      >
        Options
      </Typography>
      <Each
        of={item.options}
        render={(option, index) => (
          <OptionItem
            option={option}
            handleRemoveOption={handleRemoveOption}
            handleCheckOption={handleCheckOption}
            handleUpdateOption={handleUpdateOption}
            questionIndex={questionIndex}
            optionIndex={index}
          />
        )}
      />
      <CustomButton
        text={"Ajouter une option"}
        onClick={() => handleAddOption(questionIndex)}
        sx={{
          bgcolor: "black",
        }}
      />
    </Box>
  );
};

const AddEditFinalTest = () => {
  const { moocId, finalTestId } = useParams();
  const [questions, setQuestions] = useState([]);
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getFinalTestData = async () => {
      try {
      } catch (error) {
        toast(`${error}`);
      }
    };
    if (finalTestId) {
      getFinalTestData();
    }
  }, []);

  const onSubmit = async (values) => {
    try {
      const data = {
        name: values.name,
        description: values.description,
      };
      if (finalTestId) {
        navigate(`/moocs/${moocId}/`);
      } else {
        navigate(`/moocs/${moocId}/`);
      }
    } catch (error) {
      toast(`${error}`);
    }
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { text: "", options: [] }]);
  };

  const handleAddOption = (index) => {
    const newQuestions = questions.map((question, i) => {
      if (i === index) {
        return {
          ...question,
          options: [...question.options, { text: "", is_correct: false }],
        };
      }
      return question;
    });
    setQuestions(newQuestions);
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setQuestions(newQuestions);
  };

  const handleCheckOption = (questionIndex, optionIndex) => {
    const newQuestions = questions.map((question, i) => {
      if (i === questionIndex) {
        return {
          ...question,
          options: question.options.map((option, j) => {
            if (j === optionIndex) {
              return {
                ...option,
                is_correct: !option.is_correct,
              };
            }
            return option;
          }),
        };
      }
      return question;
    });
    setQuestions(newQuestions);
  };

  const handleRemoveOption = (questionIndex, optionIndex) => {
    const newQuestions = questions.map((question, i) => {
      if (i === questionIndex) {
        return {
          ...question,
          options: question.options.filter((_, j) => j !== optionIndex),
        };
      }
      return question;
    });
    setQuestions(newQuestions);
  };

  const handleUpdateQuestion = (index, value) => {
    const newQuestions = questions.map((question, i) => {
      if (i === index) {
        return {
          ...question,
          text: value,
        };
      }
      return question;
    });
    setQuestions(newQuestions);
  };

  const handleUpdateOption = (questionIndex, optionIndex, value) => {
    const newQuestions = questions.map((question, i) => {
      if (i === questionIndex) {
        return {
          ...question,
          options: question.options.map((option, j) => {
            if (j === optionIndex) {
              return {
                ...option,
                text: value,
              };
            }
            return option;
          }),
        };
      }
      return question;
    });
    setQuestions(newQuestions);
  };

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={{
        name: "",
        description: "",
        questions,
      }}
      // validationSchema={}
      validateOnMount={true}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Box
          // sx={{
          //   "span.error-message": {
          //     m: ".5px -10px",
          //     fontSize: "12px",
          //     color: theme.palette.error.main,
          //     position: "absolute",
          //     whiteSpace: "nowrap",
          //     overflow: "hidden",
          //     textOverflow: "ellipsis",
          //     maxWidth: "100%",
          //   },
          // }}
          sx={{}}
        >
          {isLoading ? (
            <CustomCircularProgress />
          ) : (
            <>
              <Form onSubmit={(e) => handleSubmit(e)}>
                {/* Header */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "20px 10px",
                    backgroundColor: theme.palette.primary.main,
                    position: "sticky",
                    top: "0",
                    zIndex: "1",
                  }}
                >
                  <Typography variant="h3" fontWeight="bold" color="secondary">
                    {finalTestId ? "Modifier le" : "Ajouter un"} test
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Button
                      sx={{
                        width: "100px",
                        fontSize: "16px",
                        fontWeight: "400",
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.alt.main,
                        border: "1px solid",
                        borderRadius: "4px",
                        borderColor: theme.palette.grey.light,
                        textTransform: "none",
                        ":hover": {
                          backgroundColor: theme.palette.primary[400],
                        },
                      }}
                      onClick={() => navigate(-1)}
                    >
                      Annuler
                    </Button>
                    <Button
                      sx={{
                        width: "100px",
                        fontSize: "16px",
                        fontWeight: "400",
                        backgroundColor: theme.palette.alt.main,
                        textTransform: "none",
                        ":hover": {
                          backgroundColor: theme.palette.alt[400],
                        },
                      }}
                      type="submit"
                    >
                      {finalTestId ? "Modifier" : "Ajouter"}
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    p: "20px 20px 35px 15px",
                  }}
                >
                  <Each
                    of={values.questions}
                    render={(item, index) => {
                      return (
                        <QuestionItem
                          item={item}
                          handleAddOption={handleAddOption}
                          handleRemoveQuestion={handleRemoveQuestion}
                          handleRemoveOption={handleRemoveOption}
                          handleCheckOption={handleCheckOption}
                          handleUpdateQuestion={handleUpdateQuestion}
                          handleUpdateOption={handleUpdateOption}
                          questionIndex={index}
                        />
                      );
                    }}
                  />
                  <Box>
                    <CustomButton
                      onClick={() => handleAddQuestion(values, setFieldValue)}
                      text={"Ajouter une question"}
                      sx={{
                        bgcolor: "black",
                      }}
                    />
                  </Box>
                </Box>
              </Form>
              {/* <PopUp open={addSiteSuccessOpen}>
                <AddSuccessPopUp
                  title={"Ajout de chantier confirmé"}
                  onClick={() => {
                    setAddSuccessOpen(false);
                    navigate("/sites");
                  }}
                />
              </PopUp> */}
            </>
          )}
        </Box>
      )}
    </Formik>
  );
};

export default AddEditFinalTest;
