import { configureStore } from '@reduxjs/toolkit';
import {jwtDecode} from 'jwt-decode';

import { getMe } from '../Services/Users.services';
import AuthenticationReducer from './Reducers/AuthentificationReducer';
import userReducer from './Reducers/UserReducer';
import cartReducer from './Reducers/CartReducer';
const rootReducer = {
  authentification : AuthenticationReducer,
  user : userReducer, 
  cart :cartReducer
};

// Configure le store avec Redux Toolkit et désactive la vérification de sérialisation
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Action asynchrone pour obtenir les données utilisateur depuis le stockage local
const getAsyncStorage = () => {
  return async (dispatch) => {
    const token = localStorage.getItem('@user'); // Assurez-vous que le token est stocké avec la clé appropriée
    if (token) {
      const decoded = jwtDecode(token);
      const result = await getMe(decoded.userId);
      console.log('res', result?.user)
      const user = result?.user
     console.log('store', decoded,user)
      dispatch({
        type: 'SIGN_IN_SUCCESS',
        payload: { user, token },
      });
    }
  };
};

// Dispatch l'action asynchrone au démarrage
store.dispatch(getAsyncStorage());

export default store;
