import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { gamesDataList } from "Pages/data";
import ClientGameCard from "Components/Client/Games/GameCard";
import Builder from "Components/Common/Builder";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import ErrorText from "Components/Common/ErrorText";
import Each from "Components/Common/Each";
import { toast } from "react-toastify";

const ClientGamesList = () => {
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getGames = async () => {
      try {
        setGames(gamesDataList);
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
      }
    };
    getGames();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: 2,
        padding: 3,
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Builder
        builder={() => {
          if (isLoading) {
            return <CustomCircularProgress />;
          } else if (games.length > 0) {
            return (
              <Each
                of={games}
                render={(game) => <ClientGameCard game={game} />}
              />
            );
          } else {
            return <ErrorText text="Aucune jeu trouvé" />;
          }
        }}
      />
    </Box>
  );
};

export default ClientGamesList;
