// actions/cartActions.js
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";

export const addToCart = (item) => (dispatch, getState) => {
  const { cart } = getState();
  const existingItem = cart.items.find((cartItem) => cartItem.id === item.id);
  if (existingItem) {
    alert("Item already in cart!");
    return;
  }
  dispatch({
    type: ADD_TO_CART,
    payload: item,
  });
};


export const removeFromCart = (itemId) => ({
  type: REMOVE_FROM_CART,
  payload: itemId,
});

export const clearCart = () => ({
  type: CLEAR_CART,
});

export const updateQuantity = (itemId, quantity) => ({
  type: UPDATE_QUANTITY,
  payload: { itemId, quantity },
});
