import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// import Home from '../Pages/Home';
import { useDispatch, useSelector } from "react-redux";
import NotFoundError from "Components/Common/NotFoundError";
import ClientHome from "Pages/Client/Home/Home";
import ShoppingCart from "Pages/Client/Cart/Cart";
import ClientLayout from "Components/Layout/ClientLayout";
import ClientMoocs from "Pages/Client/Moocs/ClientMoocs";
import ClientGames from "Pages/Client/Games/ClientGames";
import ClientEvents from "Pages/Client/Events/ClientEvents";
import ClientCourses from "Pages/Client/Courses/ClientCourses";
import ClientProfile from "Pages/Client/Profile/ClientProfile";

// const TestHome = () => {
//   return (
//     <Box>
//       <MainHeader />
//       <MainLayout>
//         <Box
//           sx={{
//             fontSize: "38px",
//             fontWeight: "bold",
//             color: "black",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             height: "100%",
//           }}
//         >
//           Bienvenue chez CS Consulting
//         </Box>
//       </MainLayout>
//     </Box>
//   );
// };

const ClientRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const isLoading = useSelector((state) => selectGlobalIsLoading(state));
  const user = useSelector((state) => state.authentification?.user);

  return (
    <Routes>
      <Route element={<ClientLayout />}>
        <Route exact path="/" element={<ClientHome />} />
        <Route exact path="/games/*" element={<ClientGames />} />
        <Route exact path="/moocs/*" element={<ClientMoocs />} />
        <Route exact path="/courses/*" element={<ClientCourses />} />
        <Route exact path="/events/*" element={<ClientEvents />} />
        <Route exact path="/profile/" element={<ClientProfile />} />
        <Route exact path="/cart" element={<ShoppingCart />} />
        <Route path="*" element={<NotFoundError text={"404"} />} />
      </Route>
    </Routes>
  );
};

export default ClientRoute;
