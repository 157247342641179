import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// import Home from '../Pages/Home';
import { useDispatch, useSelector } from "react-redux";
import AdminHome from "../Pages/Admin/AdminHome";
import Games from "../Pages/Admin/Games/Games";
import Layout from "../Components/Layout/Layout";
import Moocs from "Pages/Admin/Moocs/Moocs";
import Events from "Pages/Admin/Events/Events";
import Companies from "Pages/Admin/Companies/Companies";
import NotFoundError from "Components/Common/NotFoundError";
import Places from "Pages/Admin/Places/Places";
import Users from "Pages/Admin/Users/Users";
import Reservations from "Pages/Admin/Reservations/Reservations";
import Orders from "Pages/Admin/Orders/Orders";

// const TestHome = () => {
//   return (
//     <Box>
//       <MainHeader />
//       <MainLayout>
//         <Box
//           sx={{
//             fontSize: "38px",
//             fontWeight: "bold",
//             color: "black",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             height: "100%",
//           }}
//         >
//           Bienvenue chez CS Consulting
//         </Box>
//       </MainLayout>
//     </Box>
//   );
// };

const AdminRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const isLoading = useSelector((state) => selectGlobalIsLoading(state));
  const user = useSelector((state) => state.authentification?.user);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route exact path="/" element={<AdminHome />} />
        <Route exact path="/games/*" element={<Games />} />
        <Route exact path="/moocs/*" element={<Moocs />} />
        <Route exact path="/orders/*" element={<Orders />} />
        <Route exact path="/events/*" element={<Events />} />
        <Route exact path="/companies/*" element={<Companies />} />
        <Route exact path="/places/*" element={<Places />} />
        <Route exact path="/reservations/*" element={<Reservations />} />
        <Route exact path="/users/*" element={<Users />} />
        <Route path="*" element={<NotFoundError text={"404"} />} />
      </Route>
    </Routes>
  );
};

export default AdminRoute;
