import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import NotFoundError from "Components/Common/NotFoundError";
import ClientMoocDetails from "./ClientMoocDetails";
import ClientMoocsList from "./ClientMoocsList";

const ClientMoocs = () => {
  return (
    <Box>
      <Routes>
        <Route path="/" element={<ClientMoocsList />} />
        {/* <Route path="/:moocId/lessons/*" element={<ClientMoocLessons />} /> */}
        <Route path="/:moocId" element={<ClientMoocDetails />} />
        <Route path="*" element={<NotFoundError />} />
      </Routes>
    </Box>
  );
};

export default ClientMoocs;
