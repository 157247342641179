import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Builder from "Components/Common/Builder";
import ErrorText from "Components/Common/ErrorText";
import LoadingShimmer from "Components/Common/LoadingShimmer";
import { formatDate } from "helpers/format_date";
import { reservationsDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PlaceReservationList = ({ placeId }) => {
  const navigate = useNavigate();
  const [place_reservations, setPlaceReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const data = reservationsDataList.filter(
      (item) => item.place._id == placeId
    );
    console.log(data);
    if (data) {
      setPlaceReservations(data);
    }
  }, [placeId]);
  return (
    <Box
      sx={{
        border: "1px solid black",
        borderRadius: "6px",
        pt: "20px",
        flex: "1",
        overflowY: "auto",
      }}
    >
      <Typography
        fontSize="20px"
        fontWeight="bold"
        color={"grey"}
        sx={{
          pl: "15px",
        }}
      >
        Reservations
      </Typography>
      <Builder
        builder={() => {
          if (isLoading) {
            return <LoadingShimmer />;
          }
          if (place_reservations.length > 0) {
            return (
              <Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    color={"grey"}
                    fontSize={"16px"}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    m="10px"
                    p="0 30px"
                  >
                    <Typography variant="h6">Date de debut</Typography>
                    <Typography variant="h6">Date de fin</Typography>
                  </Box>
                  <Divider
                    sx={{
                      textAlign: "center",
                      height: "2px",
                      width: "100%",
                      backgroundColor: "black",
                    }}
                  />
                </Box>
                <List>
                  {place_reservations.map((item) => (
                    <Box key={item._id}>
                      <ListItem
                        sx={{
                          padding: "10px 30px",
                          ":hover": {
                            boxShadow: 3,
                          },
                          cursor: "pointer",
                        }}
                        disablePadding
                        secondaryAction={
                          <Typography
                            variant="h6"
                            sx={{
                              color: "secondary.main",
                              textAlign: "start",
                              ml: "-50px",
                            }}
                          >
                            {formatDate(item.end_date)}
                          </Typography>
                        }
                        onClick={() => navigate(`/reservations/${item._id}`)}
                      >
                        <ListItemText
                          sx={{ color: "black" }}
                          primaryTypographyProps={{
                            style: {
                              color: "secondary.main",
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                          }}
                          secondaryTypographyProps={{
                            style: {
                              color: "grey",
                              fontSize: "14px",
                            },
                          }}
                          primary={formatDate(item.start_date)}
                        />
                      </ListItem>
                      <Divider
                        sx={{
                          textAlign: "center",
                          height: "1px",
                          backgroundColor: "black",
                          m: "0 10px",
                        }}
                      />
                    </Box>
                  ))}
                </List>
              </Box>
            );
          } else {
            return (
              <ErrorText
                text="Aucune Réservation trouvé !"
                sx={{ height: "80%" }}
              />
            );
          }
        }}
      />
    </Box>
  );
};

export default PlaceReservationList;
