import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  Divider,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { eventsDataList } from "Pages/data";
import logo from "Images/logo.png";
import { ArrowBackOutlined } from "@mui/icons-material";
import Builder from "Components/Common/Builder";
import CustomCountDown from "Components/Common/CustomCountDown";
import { toast } from "react-toastify";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import ErrorText from "Components/Common/ErrorText";

const ClientEventDetails = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchGame = async () => {
      try {
        const data = eventsDataList.find((event) => event._id == eventId);
        setEvent(data);
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
      }
    };
    fetchGame();
  }, [eventId]);

  return (
    <Box
      sx={{
        backgroundColor: "#121212",
        color: "#fff",
        padding: 2,
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          marginBottom: 2,
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackOutlined />
        </IconButton>
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Retour
        </Typography>
      </Box>
      {/* Hero Section */}
      <Builder
        builder={() => {
          if (isLoading) {
            return (
              <CustomCircularProgress
                sx={{
                  height: "70%",
                }}
                style={{ color: "#fff" }}
              />
            );
          } else if (event) {
            return (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 4,
                    alignItems: { xs: "center", md: "flex-start" },
                  }}
                >
                  {/* Game Image */}
                  <Card
                    sx={{
                      maxWidth: "400px",
                      borderRadius: 3,
                      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.5)",
                      overflow: "hidden",
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt={event.name}
                      image={logo}
                      sx={{ objectFit: "cover", height: 400 }}
                    />
                  </Card>

                  {/* Game Details */}
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="h3"
                      sx={{ fontWeight: "bold", marginBottom: 2 }}
                    >
                      {event.name}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="orange"
                      sx={{ marginBottom: 2 }}
                    >
                      {event.paying ? `${event.price} DT` : "Gratuit"}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="#aaa"
                      sx={{ marginBottom: 2 }}
                    >
                      {event.description}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="#aaa"
                      sx={{ marginBottom: 1 }}
                    >
                      <strong>Jeux:</strong>
                      {event.games.map((game) => (
                        <Typography
                          key={game._id}
                          sx={{
                            color: "orange",
                            marginLeft: 5,
                            ":hover": {
                              color: "#ba8727",
                            },
                            cursor: "pointer",
                          }}
                          onClick={() => navigate(`/games/${game._id}`)}
                        >
                          {game.name}
                        </Typography>
                      ))}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="#aaa"
                      sx={{ marginBottom: 1 }}
                    >
                      <strong>Status:</strong> {event.status}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="#aaa"
                      sx={{ marginBottom: 1 }}
                    >
                      <strong>Requirements:</strong>{" "}
                      {event.requirements || "Non"}
                    </Typography>
                    <CustomCountDown end_date={event.end_date} />
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        marginTop: 3,
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "orange",
                          color: "#fff",
                          fontWeight: "bold",
                          ":hover": {
                            backgroundColor: "#e69500",
                          },
                        }}
                        onClick={() => {}}
                      >
                        Réserver maintenant
                      </Button>
                    </Box>
                  </Box>
                </Box>

                <Divider sx={{ marginY: 4, borderColor: "#333" }} />
                <Box>
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", marginBottom: 3 }}
                  >
                    A propos de l'événement
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#aaa"
                    sx={{ marginBottom: 2 }}
                  >
                    {event.description}
                  </Typography>
                </Box>
              </Box>
            );
          } else {
            return (
              <ErrorText
                text="Evénement non trouvé"
                style={{ color: "#fff" }}
              />
            );
          }
        }}
      />
    </Box>
  );
};

export default ClientEventDetails;
