import { Box, IconButton, Typography } from "@mui/material";
import { lessonsDataList } from "Pages/data";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import video from "Images/test.mkv";
import QuestionItem from "Components/Client/Lessons/QuestionItem";
import Builder from "Components/Common/Builder";
import { Form, Formik } from "formik";
import CustomButton from "Components/Common/CustomButton";
import { toast } from "react-toastify";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import ErrorText from "Components/Common/ErrorText";
import Each from "Components/Common/Each";
import { ArrowBackOutlined } from "@mui/icons-material";

const ClientLessonDetails = () => {
  const { lessonId } = useParams();
  const navigate = useNavigate();
  const [lesson, setLesson] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getLessonDetails = async () => {
      try {
        const data = lessonsDataList.find((lesson) => lesson._id == lessonId);
        setLesson(data);
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
      }
    };
    getLessonDetails();
  }, [lessonId]);

  const handleSubmit = async () => {
    try {
      // submit lesson
      // [POST] /users/me/moocs/:moocId/lessons/:lessonId/submit
      // navigate to next lesson
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        overflowY: "auto",
        backgroundColor: "#121212",
        padding: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          marginBottom: 2,
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackOutlined />
        </IconButton>
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Retour
        </Typography>
      </Box>
      {/* attachment */}
      <Builder
        builder={() => {
          if (isLoading) {
            return (
              <CustomCircularProgress
                sx={{
                  height: "70%",
                }}
                style={{ color: "#fff" }}
              />
            );
          } else if (
            lesson &&
            lesson.questions &&
            lesson.questions.length > 0
          ) {
            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  gap: "10px",
                  padding: "20px",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    position: "sticky",
                    top: "0",
                  }}
                >
                  <video
                    src={video}
                    controls
                    width="100%"
                    height="100%"
                    style={{
                      borderRadius: "10px",
                      boxShadow: "0px 0px 10px 0px #000",
                    }}
                  ></video>
                </Box>
                {/* questions */}
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  <Formik
                    initialValues={{}}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                  >
                    {({ values, handleSubmit, setFieldValue }) => (
                      <Form>
                        <Each
                          of={lesson.questions}
                          render={(item) => <QuestionItem question={item} />}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <CustomButton text="Valider" type="submit" />
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            );
          } else {
            return (
              <ErrorText
                text="Lecon non trouvée"
                style={{ color: "#fff" }}
                sx={{ height: "70%" }}
              />
            );
          }
        }}
      />
    </Box>
  );
};

export default ClientLessonDetails;
