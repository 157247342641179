import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import CustomField from "Components/Common/CustomField";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import { toast } from "react-toastify";
import CustomDropDown from "Components/Common/CustomDropDown";

const AddEditUser = () => {
  const { userId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const userRoles = ["ADMIN", "CLIENT", "COLLABORATOR", "COMPANY"];
  const getUserRoleLabel = (role) => {
    const labels = {
      ADMIN: "Admin",
      CLIENT: "Client",
      COLLABORATOR: "Collaborateur",
      COMPANY: "Société",
    };
    return labels[role] || "Role";
  };
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getUserData = async () => {
      try {
      } catch (error) {
        toast(`${error}`);
      }
    };
    if (userId) {
      getUserData();
    }
  }, []);

  const onSubmit = async (values) => {
    try {
      const data = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        role: values.role,
      };
      if (userId) {
        navigate(`/users/`);
      } else {
        navigate(`/users/`);
      }
    } catch (error) {
      toast(`${error}`);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={onSubmit}
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        role: userRoles[0],
      }}
      // validationSchema={}
      validateOnMount={true}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Box>
          {isLoading ? (
            <CustomCircularProgress />
          ) : (
            <>
              <Form onSubmit={(e) => handleSubmit(e)}>
                {/* Header */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "20px 10px",
                    backgroundColor: theme.palette.primary.main,
                    position: "sticky",
                    top: "0",
                    zIndex: "1",
                  }}
                >
                  <Typography variant="h3" fontWeight="bold" color="secondary">
                    Nouveau utilisateur
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Button
                      sx={{
                        width: "100px",
                        fontSize: "16px",
                        fontWeight: "400",
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.alt.main,
                        border: "1px solid",
                        borderRadius: "4px",
                        borderColor: theme.palette.grey.light,
                        textTransform: "none",
                        ":hover": {
                          backgroundColor: theme.palette.primary[400],
                        },
                      }}
                      onClick={() => navigate(-1)}
                    >
                      Annuler
                    </Button>
                    <Button
                      sx={{
                        width: "100px",
                        fontSize: "16px",
                        fontWeight: "400",
                        backgroundColor: theme.palette.alt.main,
                        textTransform: "none",
                        ":hover": {
                          backgroundColor: theme.palette.alt[400],
                        },
                      }}
                      type="submit"
                    >
                      {userId ? "Modifier" : "Ajouter"}
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderTopLeftRadius: "9px",
                    borderTopRightRadius: "9px",
                    padding: "5px",
                    p: "20px 20px 35px 15px",
                    backgroundColor: theme.palette.grey[100],
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    color={theme.palette.secondary.main}
                  >
                    Informations Général
                  </Typography>
                  <CustomField
                    value={values.first_name}
                    isDisabled={false}
                    name="first_name"
                    type="text"
                    title="Nom"
                  />
                  <CustomField
                    value={values.last_name}
                    isDisabled={false}
                    name="last_name"
                    type="text"
                    title="Prénom"
                  />
                  <CustomField
                    value={values.email}
                    isDisabled={false}
                    name="email"
                    type="email"
                    title="Email"
                  />
                  <CustomField
                    value={values.phone}
                    isDisabled={false}
                    name="phone"
                    type="test"
                    title="Téléphone"
                  />
                  <Typography
                    height="15px"
                    variant="h6"
                    color={theme.palette.grey[600]}
                    mb=".6rem"
                    ml="5px"
                  >
                    Roles
                  </Typography>
                  <CustomDropDown
                    name="role"
                    value={values.role}
                    items={userRoles}
                    getItems={(item) => getUserRoleLabel(item)}
                  />
                </Box>
              </Form>
              {/* <PopUp open={addSiteSuccessOpen}>
                <AddSuccessPopUp
                  title={"Ajout de chantier confirmé"}
                  onClick={() => {
                    setAddSuccessOpen(false);
                    navigate("/sites");
                  }}
                />
              </PopUp> */}
            </>
          )}
        </Box>
      )}
    </Formik>
  );
};

export default AddEditUser;
