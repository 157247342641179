import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { coursesDataList } from "Pages/data";
import ClientCourseCard from "Components/Client/Courses/ClientCourseCard";
import Builder from "Components/Common/Builder";
import { toast } from "react-toastify";
import CustomCircularProgress from "Components/Common/CustomCircularProgress";
import Each from "Components/Common/Each";
import ErrorText from "Components/Common/ErrorText";

const ClientCoursesList = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCourses = async () => {
      try {
        setCourses(coursesDataList);
        setIsLoading(false);
      } catch (error) {
        toast.error(error);
      }
    };
    getCourses();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: 2,
        padding: 3,
        height: "calc(100vh - 60px)",
        overflowY: "auto",
      }}
    >
      <Builder
        builder={() => {
          if (isLoading) {
            return <CustomCircularProgress />;
          } else if (courses.length > 0) {
            return (
              <Each
                of={courses}
                render={(course) => <ClientCourseCard course={course} />}
              />
            );
          } else {
            return <ErrorText text="Aucune cours trouvé" />;
          }
        }}
      />
    </Box>
  );
};

export default ClientCoursesList;
