import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  LinearProgress,
} from "@mui/material";
import image from "Images/logo.png";
import { useNavigate } from "react-router-dom";
const ClientCourseCard = ({ course }) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "200px",
        width: "180px",
        backgroundColor: "#1e1e1e",
        color: "#fff",
        borderRadius: 2,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
        maxWidth: 280,
        margin: 2,
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        ":hover": {
          transform: "scale(1.05)",
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.5)",
        },
        cursor: "pointer",
      }}
      onClick={() => navigate(`/courses/${course._id}`)}
    >
      <CardMedia
        component="img"
        height="140"
        image={image}
        alt={course.mooc.title}
        sx={{ borderRadius: "8px 8px 0 0" }}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: 1,
          padding: 2,
          ":last-child": {
            paddingBottom: 1,
          },
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {course.mooc.title}
        </Typography>
        {/* <Typography variant="body2" color="#aaa">
          {course.game.level}
        </Typography> */}
        <LinearProgress
          variant="determinate"
          value={
            (course.completed_lessons.length / course.mooc.lessons.length) * 100
          }
          sx={{
            height: 8,
            borderRadius: 2,
            backgroundColor: "#f3f3f3",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#FF9800",
            },
          }}
          //
        />
      </CardContent>
    </Card>
  );
};

export default ClientCourseCard;
